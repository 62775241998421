import Hero from '../components/layout/main/Hero';
import Featured from '../components/layout/main/Featured';
import About from '../components/layout/main/About';
import Team from '../components/layout/main/Team';
import Contact from '../components/layout/main/Contact';
import Footer from '../components/layout/Footer';

export default function Home() {
    return (
        <>
            <Hero />
            <Featured />
            <About />
            <Team />
            <Contact />
            <Footer />
        </>
    );
}