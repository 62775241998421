// import Navbar from '@/components/layout/Navbar';
import Navbar from '../Navbar';
import Header from './Header';

import HeaderImage from '../../../assets/images/header.jpg'

const Hero = () => {

    return (
        <section className='h-screen bg-cover bg-center' style={{ backgroundImage: `url(${HeaderImage})` }}>
            <Navbar />
            <div className='flex justify-center md:mt-52 mt-[35%]'>
                <Header />
            </div>
        </section>
    );
};

export default Hero;