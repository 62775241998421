import React from 'react';

interface TitleProps {
    text: string;
}

const Title: React.FC<TitleProps> = ({ text }) => {
    return (
        <h1 className='text-primary-text font-bold md:text-6xl text-3xl mx-auto'>{text}</h1>
    );
};

export default Title;