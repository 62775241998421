import React from 'react';

interface ButtonProps {
    text: string;
    link: string;
    className?: string;
}

const Button: React.FC<ButtonProps> = ({ text, link, className }) => {
    return (
        <a href={link}>
            <p className={`px-12 py-4 border-10 uppercase bg-primary hover:bg-secondary rounded-full text-sm font-bold text-primary-text duration-300 ease-in-out transform ${className}`}>{text}</p>
        </a>
    );
};

export default Button;