import { FaMapMarkerAlt, FaEnvelope, FaFacebook, FaInstagram } from 'react-icons/fa';
import Button from '../../common/Button';
import Divider from '../../common/Divider';
import SectionTitle from '../../common/SectionTitle';

import MapImage from '../../../assets/images/map.png';

const Contact = () => {

    return (
        <section className='py-16 flex flex-col items-center bg-[#181818] bg-fixed bg-cover bg-center bg-no-repeat' style={{ backgroundImage: `url(${MapImage})` }}>
            <SectionTitle text={'Bezoek Ons'} light={true} />
            <Divider />
            {/* Contact Details  (Left)*/}
            <div className='mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-10 pt-8 w-3/4'>
                <div className='p-8 text-primary-text'>
                    <div className='flex items-center mb-4'>
                        <h2 className='text-base font-bold'>Contacteer Ons</h2>
                        <div className='flex-grow border-b border-white ml-4'></div>
                    </div>
                    <div className='space-y-4'>
                        <div className='flex items-center'>
                            <FaMapMarkerAlt size={20} className='mr-4' />
                            <address className='not-italic'>
                                <p>Borsbeeksesteenweg 45, 2530</p>
                                <p>Boechout</p>
                            </address>
                        </div>
                        <a href='mailto:info@robinhoodboechout.be' className='flex items-center'>
                            <FaEnvelope size={20} className='mr-4' />
                            <p>info@robinhoodboechout.be</p>
                        </a>
                    </div>
                    {/* Google Maps Section */}
                    <div className='flex items-center mt-6 mb-4'>
                        <h2 className='text-base font-bold'>Vind Ons</h2>
                        <div className='flex-grow border-b border-white ml-4'></div>
                    </div>
                    <div className='space-y-4'>
                        <iframe className='w-full h-60' loading='lazy' src='https://maps.google.com/maps?q=Robin%20Hood%20Boechout&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near' title='Robin Hood Boechout' aria-label='Robin Hood Boechout'></iframe>
                    </div>
                    {/* Socials Section */}
                    <div className='flex items-center mt-6 mb-4'>
                        <h2 className='text-base font-bold'>Volg Ons</h2>
                        <div className='flex-grow border-b border-white ml-4'></div>
                    </div>
                    <div className='space-y-4'>
                        <div className='flex items-center'>
                            <a target='_blank' href='https://www.facebook.com/people/Robin-Hood-Boechout/100057704407940/'>
                                <FaFacebook size={30} className='mr-2' />
                            </a>
                            <a target='_blank' href='https://www.instagram.com/robin_hood_boechout/'>
                                <FaInstagram size={30} className='ml-2' />
                            </a>
                        </div>
                    </div>
                </div>

                {/* Contact Form  (Right)*/}
                <div className='flex justify-center items-cente'>
                    <form className='bg-white p-8 rounded shadow-md w-full max-w-md'>
                        <SectionTitle text={'Contacteer Ons'} light={false} />
                        <Divider />

                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='email'>
                                Email Adres
                            </label>
                            <input
                                required
                                type='email'
                                id='email'
                                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                placeholder='Email Adres'
                            />
                        </div>

                        <div className='mb-6'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='message'>
                                Bericht
                            </label>
                            <textarea
                                id='message'
                                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                placeholder='Type uw bericht hier...'
                                rows={4}
                            ></textarea>
                        </div>

                        <div className='flex items-center justify-between'>
                            <Button text={'Verzenden'} link={''} />
                        </div>
                    </form>
                </div>


            </div>
        </section>
    );
};

export default Contact;