import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight, FaSignOutAlt } from 'react-icons/fa';
import axios from 'axios';

import Logo from '../../assets/images/logo-black-lg.png';

interface SidebarContextType {
  expanded: boolean;
}

interface SidebarProps {
  children: ReactNode;
}

interface SidebarItemProps {
  icon: ReactNode;
  text: string;
  to: string;
  active?: boolean;
  alert?: boolean;
}

interface Payload {
  name: string;
  role: string;
}

const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

const Sidebar: React.FC<SidebarProps> = ({ children }) => {
  const [expanded, setExpanded] = useState<boolean>(window.innerWidth > 768);
  const [payload, setPayload] = useState<Payload | null>(null);
  const token = sessionStorage.getItem('access_token');
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get('https://api.robinhoodboechout.be/api/v1/auth/verify', options);
        setPayload(response.data);

      } catch (error) {
        sessionStorage.removeItem('access_token');
      }
    };

    if (token) {
      verifyToken();
    } else {
      sessionStorage.removeItem('access_token');
    }
  }, [token]);

  const signOut = () => {
    sessionStorage.removeItem('access_token');
    navigate('/');
  }

  return (
    <>
      <aside className='h-screen'>
        <nav className='h-full flex flex-col bg-white border-r shadow-sm'>
          <div className='p-4 pb-2 flex justify-between items-center'>
            <img src={Logo} className={`overflow-hidden transition-all ${expanded ? 'w-32' : 'w-0'}`} alt='Logo' />
            <button onClick={() => setExpanded((curr) => !curr)} className='p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100'>
              {expanded ? <FaChevronLeft /> : <FaChevronRight />}
            </button>
          </div>

          <SidebarContext.Provider value={{ expanded }}>
            <ul className='flex-1 px-3'>{children}</ul>
          </SidebarContext.Provider>

          <div className='border-t flex p-3'>
            <div className={`flex justify-between items-center overflow-hidden transition-all ${expanded ? 'w-52 ml-3' : 'w-0'}`}>
              <div className='leading-4'>
                <h4 className='font-semibold'>{payload?.name}</h4>
                <span className='text-xs text-gray-600'>{payload?.role}</span>
              </div>
            </div>
            <div className='flex justify-between items-center overflow-hidden transition-all ml-3'>
              <span onClick={signOut} className='text-red-400 hover:cursor-pointer'>
                <FaSignOutAlt size={20} />
              </span>
            </div>

          </div>
        </nav>
      </aside>
    </>
  );
};

const SidebarItem: React.FC<SidebarItemProps> = ({ icon, text, to, active, alert }) => {
  const context = useContext(SidebarContext);

  if (!context) {
    throw new Error('SidebarItem must be used within a SidebarContext');
  }

  const { expanded } = context;

  return (
    <Link to={to} className="block">
      <li className={`relative flex items-center py-2 px-3 my-1 font-medium rounded-md cursor-pointer transition-colors group ${active ? 'bg-gradient-to-tr from-green-200 to-green-100 text-secondary' : 'hover:bg-green-50 text-gray-600'}`}>
        {icon}
        <span className={`overflow-hidden transition-all ${expanded ? 'w-52 ml-3' : 'w-0'}`}>{text}</span>
        {alert && (
          <div className={`absolute right-2 w-2 h-2 rounded bg-red-400 ${expanded ? '' : 'top-2'}`}></div>
        )}

        {!expanded && (
          <div className={`absolute left-full rounded-md px-2 py-1 ml-6 bg-green-100 text-secondary text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`}>
            {text}
          </div>
        )}
      </li>
    </Link>
  );
};

export { Sidebar, SidebarItem };