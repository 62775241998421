import Divider from '../../common/Divider';
import GoogleCalendar from '../../common/GoogleCalendar';
import SectionTitle from '../../common/SectionTitle';


export default function Info() {
    return (
        <>
            <section className='py-16'>
                <div className='mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-10 w-3/4'>
                    <div className='w-full items-center md:text-left md:items-start md:w-3/4'>
                        <SectionTitle text={'Introductie'} light={false} />
                        <Divider />
                        <p className='pb-2'>
                            Luchtdoelschieten is een recreative discipline waarbij men met pijl en
                            boog schijven uit de lucht probeert te schieten die door een machine
                            gekatapulteerd worden.
                        </p>
                        <p className='pb-4'>
                            Volg onze Luchtdoelschieten pagina ook zeker op <a className='text-primary hover:text-secondary' target='_blank' href='https://www.facebook.com/luchtdoelschietenboechout'>Facebook</a>!
                        </p>
                        <p className='italic text-gray-500'>
                            Door slecht weer kunnen sessies altijd afgelast worden. Raadpleeg facebookpagina, Ulrik van Oosterwyck of luchtdoelschieten Boechout na 11.00h.
                        </p>
                    </div>

                    <div className='w-full'>
                        <SectionTitle text={'Agenda'} light={false} />
                        <Divider />
                        <GoogleCalendar calendar={'luchtdoelschieten'} />
                    </div>
                </div>

            </section >
        </>
    );
}