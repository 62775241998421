import React from 'react';

interface TableProps {
    heading: string[];
    rows: string[][];
}

const DataTable: React.FC<TableProps> = ({ heading, rows }) => {
    return (
        <table className={`pricing-table table-auto`}>
            <thead>
                <tr>
                    {heading.map(head => (
                        <th>{head}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {rows.map((row, index) => (
                    <tr key={index}>
                        {row.map((item, index) => (
                            <td key={index} className={index == 0 ? 'font-bold md:font-normal' : ''} data-label={heading[index]}>{item}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default DataTable;