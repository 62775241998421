import React from 'react';
import { FaRegCalendarAlt, FaUserEdit  } from 'react-icons/fa';

interface PostCardProps {
    title: string;
    description: string;
    createdAt: string;
    slug: string;
    image?: string;
    tag?: string;
    author?: string;
}

const PostCard: React.FC<PostCardProps> = ({ title, description, createdAt, slug, image, tag, author }) => {
    return (
        <>
            <div className='rounded overflow-hidden shadow-lg flex flex-col w-80'>
                <div className='relative'>
                    <a href={`/nieuws/${slug}`}>
                        <img className='w-full'
                            src={image}
                            alt={title} />
                        <div
                            className='hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25'>
                        </div>
                    </a>
                    {tag &&
                        <div className='text-xs absolute top-0 right-0 bg-accent px-4 py-2 font-bold text-white mt-3 mr-3'>
                            {tag}
                        </div> 
                    }

                </div>
                <div className='px-6 py-4 mb-auto'>
                    <a href={`/nieuws/${slug}`} className='font-medium text-lg inline-block hover:text-accent transition duration-500 ease-in-out mb-2'>
                        {title}
                    </a>
                    <p className='text-gray-500 text-sm truncate'>
                        {description}
                    </p>
                    <a href={`/nieuws/${slug}`} className='font-medium text-xs inline-block text-accent mb-2'>
                        Meer lezen
                    </a>
                </div>
                <div className='px-6 py-3 flex flex-row items-center justify-between bg-gray-100'>
                    <span className='py-1 text-xs font-regular text-gray-900 mr-1 flex flex-row items-center'>
                        <FaRegCalendarAlt size={15} />
                        <span className='ml-1'>{createdAt}</span>
                    </span>

                    <span className='py-1 text-xs font-regular text-gray-900 mr-1 flex flex-row items-center'>
                        <FaUserEdit size={15} />
                        <span className='ml-1'>{author}</span>
                    </span>
                </div>
            </div>
        </>
    );
};

export default PostCard;