import Banner from '../components/layout/Banner';
import Footer from '../components/layout/Footer';
import PostsGrid from '../components/layout/nieuws/PostsGrid';

import BackgroundImage from '../assets/images/trainingen.jpg';

export default function Nieuws() {
    return (
        <>
            <Banner title={'Nieuws'} image={BackgroundImage} />
            <PostsGrid />
            <Footer />
        </>
    );
}