import Banner from '../components/layout/Banner';
import Footer from '../components/layout/Footer';
import Gallery from '../components/layout/luchtdoelschieten/Gallery';
import Info from '../components/layout/luchtdoelschieten/Info';

import BackgroundImage from '../assets/images/trainingen.jpg';

export default function Luchtdoelschieten() {
    return (
        <>
            <Banner title={'Luchtdoelschieten'} image={BackgroundImage} />
            <Info />
            <Gallery />
            <Footer />
        </>
    );
}