import Divider from "../../../common/Divider";
import Title from "../../../common/Title";
import { FaRegCalendarAlt, FaUserEdit  } from 'react-icons/fa';

interface PostBannerProps {
    title: string;
    created_at: string;
    author?: string;
    image?: string;
}

const PostBanner: React.FC<PostBannerProps> = ({ title, created_at, author, image }) => {
    return (
        <section className={`md:h-[45vh] h-[40vh] bg-cover bg-center`} style={{ backgroundImage: `url(${image})` }}>
            <div className='bg-[rgba(0,0,0,0.5)] md:h-[45vh] h-[40vh] flex items-center justify-center'>
                <div className='flex flex-col items-center'>
                    <Title text={title} />
                    <Divider />
                    <div className='text-primary-text py-1 font-regular text-gray-900 mr-1 flex flex-row items-center'>
                        <FaRegCalendarAlt size={15} /> &nbsp;{created_at} • &nbsp;<FaUserEdit size={15} /> &nbsp;{author}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PostBanner;