import Calendar from '../components/layout/agenda/Calendar';
import Banner from '../components/layout/Banner';
import Footer from '../components/layout/Footer';

import BackgroundImage from '../assets/images/trainingen.jpg';
  
export default function Agenda() {
    return (
        <>
            <Banner title={'Agenda\'s'} image={BackgroundImage} />
            <Calendar />
            <Footer />
        </>
    );
}