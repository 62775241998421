import Banner from '../components/layout/Banner';
import Footer from '../components/layout/Footer';
import Trainers from '../components/layout/trainingen/Trainers';
import TrainingHours from '../components/layout/trainingen/TrainingHours';

import BackgroundImage from '../assets/images/trainingen.jpg';

export default function Trainingen() {
    return (
        <>
            <Banner title={'Onze Trainingen'} image={BackgroundImage} />
            <TrainingHours />
            <Trainers />
            <Footer />
        </>
    );
}