interface HoursCardProps {
    hours: Day[];
}

interface Day {
    day: string;
    hours: string;
    details: string;
}

const HoursCard: React.FC<HoursCardProps> = ({ hours }) => {
    const getAdjustedDayIndex = () => {
        const dayIndex = new Date().getDay();
        // Shift the day index: 0 (Sunday) becomes 6, 1 (Monday) becomes 0, and so on
        return (dayIndex === 0) ? 6 : dayIndex - 1;
    };
    return (
        <>
            <div className='overflow-hidden shadow-lg bg-white p-4 max-w-3xl min-w-sm'>
                <table className='w-full mt-4 border-collapse'>
                    <tbody>
                        {hours.map((object, index) => (
                            <tr key={index} className={index === getAdjustedDayIndex() ? 'active' : ''}>
                                <td className='p-4 text-xs font-bold tracking-wider uppercase'>
                                    {object.day}
                                </td>
                                <td className='p-4'>
                                    {object.hours}
                                </td>
                                <td className='p-4 text-sm'>
                                    {object.details}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </>
    );
};

export default HoursCard;