import Divider from '../../common/Divider';
import Heading from '../../common/Heading';


const PrivacyPolicy = () => {

    return (
        <section className='py-4 flex flex-col items-center'>
            <div className='container md:w-[60%]'>
                <div className='p-8 whitespace-pre-wrap md:text-left md:items-left'>
                    <Heading text={'1. Algemeen'} light={false} subHeading={false} />
                    <Divider />
                    <p>
                        <b>Robin Hood Boechout</b> hecht veel waarde aan je privacy en de bescherming van je persoonsgegevens. In deze privacyverklaring willen we heldere en
                        transparante informatie geven over hoe wij omgaan met persoonsgegevens. Wij doen er alles aan om je privacy te waarborgen en gaan daarom
                        zorgvuldig om met persoonsgegevens. <b>Robin Hood Boechout</b> houdt zich in alle gevallen aan de toepasselijke wet- en regelgeving, waaronder de
                        Algemene Verordening Gegevensbescherming (ook gekend als GDPR).
                    </p>
                    <br />
                    <span>
                        Dit brengt met zich mee dat wij in ieder geval:
                    </span>
                    <br />
                    <br />
                    <ul className='md:ml-16 ml-6'>
                        <li className='list-disc'>
                            Je persoonsgegevens verwerken in overeenstemming met het doel waarvoor deze zijn verstrekt, deze doelen en type
                            persoonsgegevens zijn beschreven in deze Privacyverklaring.
                        </li>
                        <li className='list-disc'>
                            Verwerking van je persoonsgegevens beperkt is tot enkel die gegevens welke nodig zijn voor de doeleinden waarvoor ze worden verwerkt.
                        </li>
                        <li className='list-disc'>
                            Vragen om je uitdrukkelijke toestemming als wij deze nodig hebben voor de verwerking van je persoonsgegevens.
                        </li>
                        <li className='list-disc'>
                            Passende technische en organisatorische maatregelen hebben genomen zodat de beveiliging van je persoonsgegevens gewaarborgd is.
                        </li>
                        <li className='list-disc'>
                            Geen persoonsgegevens doorgeven aan andere partijen, tenzij dit nodig is voor uitvoering van de doeleinden waarvoor ze zijn verstrekt.
                        </li>
                        <li className='list-disc'>
                            Op de hoogte zijn van je rechten als betrokken persoon omtrent je persoonsgegevens, je hierop willen attent maken en deze willen respecteren.
                        </li>
                    </ul>
                    <br />
                    <p>
                        Als <b>Robin Hood Boechout</b> zijn wij verantwoordelijk voor de verwerking van je persoonsgegevens. Indien je na het doornemen van onze
                        privacyverklaring, of in algemenere zin, vragen heeft hierover of contact met ons wenst op te nemen kan dit via onderstaande
                        contactgegevens:
                        <br />
                        <br />
                        <b>Robin Hood Boechout</b><br />
                        Zetel: Boechout<br />
                        Emailadres: <a className='text-primary hover:text-secondary' href='mailto:info@robinhoodboechout.be'>info@robinhoodboechout.be</a>
                    </p>
                    <br />
                    <Heading text={'1.1 Waarom verwerken wij persoonsgegevens?'} light={false} subHeading={true}/>
                    <Divider />
                    <p>Je persoonsgegevens worden door <b>Robin Hood Boechout</b> verwerkt ten behoeve van de volgende doeleinden en rechtsgronden:</p>
                    <br />
                    <ul className='md:ml-16 ml-6'>
                        <li className='list-disc'>Om te kunnen deelnemen aan de activiteiten van <b>Robin Hood Boechout</b> (uitvoering overeenkomst)</li>
                        <li className='list-disc'>Het versturen van nieuwsbrieven en uitnodigingen. (gerechtvaardigd belang)</li>
                        <li className='list-disc'>Het bekomen van subsidiëring door de overheid (wettelijke verplichting)</li>
                    </ul>
                    <br />
                    <Heading text={'1.2 Welke gegevens verwerken we?'} light={false} subHeading={true}/>
                    <Divider />
                    <p>Wij kunnen de volgende persoonsgegevens van je vragen, opslaan, verzamelen en verwerken voor de volgende doelstellingen:</p>
                    <br />
                    <ul className='md:ml-16 ml-6'>
                        <li className='list-disc'>Identificatiegegevens: naam, voornaam, adres, telefoonnummer, e-mail.</li>
                        <li className='list-disc'>Identiteitsgegevens uitgegeven door overheid: identiteitskaartnummer.</li>
                        <li className='list-disc'>Rijksregisternummer.</li>
                        <li className='list-disc'>Persoonlijke kenmerken: geslacht, geboortedatum, geboorteplaats, nationaliteit.</li>
                    </ul>
                    <br />
                    <Heading text={'1.3 Wie verwerkt de gegevens?'} light={false} subHeading={true}/>
                    <Divider />
                    <p>De gegevens worden verwerkt door verantwoordelijke ledenadministratie van de vereniging.</p>
                    <br />
                    <Heading text={'1.4 Verstrekking aan derden'} light={false} subHeading={true}/>
                    <Divider />
                    <p>De gegevens die je aan ons geeft kunnen wij aan derde partijen verstrekken indien dit noodzakelijk is voor uitvoering van de hierboven
                    beschreven doeleinden.</p>
                    <br />
                    <span>Zo maken wij gebruik van een derde partij (verwerker) voor:</span>
                    <br /><br />
                    <ul className='md:ml-16 ml-6'>
                        <li className='list-disc'>Het verzorgen van de internetomgeving (webhosting).</li>
                        <li className='list-disc'>Het verzorgen van IT-infrastructuur (o.a. IT-netwerk, …).</li>
                        <li className='list-disc'>Het verzorgen (en verspreiden) van nieuwsbrieven en uitnodigingen.</li>
                    </ul>
                    <br />
                    <p>Wij geven nooit persoonsgegevens door aan verwerkers (andere partijen) dan diegene waarmee we een verwerkersovereenkomst hebben
                    afgesloten.
                    <br /><br />
                    Met deze partijen (verwerkers) maken wij hierin uiteraard de nodige afspraken om de beveiliging van je persoonsgegevens te waarborgen.
                    <br /><br />
                    Verder zullen wij de verstrekte gegevens niet aan derden doorgeven, tenzij dit wettelijk verplicht en/of toegestaan is, zoals bv. in het kader
                    van een politioneel of gerechtelijk onderzoek.
                    <br /><br />
                    Tevens kunnen wij persoonsgegevens delen met derden indien je ons hier toestemming voor geeft. Deze toestemming kan te allen tijde
                    ingetrokken worden, zonder dat dit afbreuk doet aan de rechtmatigheid van de verwerking voor de intrekking daarvan.
                    Wij verstrekken geen persoonsgegevens aan partijen die gevestigd zijn buiten de EU.</p>
                    <br />
                    <Heading text={'1.5 Minderjarigen'} light={false} subHeading={true}/>
                    <Divider />
                    <p>Wij verwerken alleen persoonsgegevens van personen jonger dan 18 jaar indien daarvoor schriftelijke toestemming is gegeven door de ouder of wettelijke vertegenwoordiger.</p>
                    <br />
                    <Heading text={'1.6 Bewaartermijn'} light={false} subHeading={true}/>
                    <Divider />
                    <p><b>Robin Hood Boechout</b> bewaart persoonsgegevens niet langer dan noodzakelijk voor het doel waarvoor deze zijn verstrekt dan wel op
                    grond van de wet is vereist. <b>Robin Hood Boechout</b> verbindt zich ertoe de gegevens niet langer bij te houden dan noodzakelijk.</p>
                    <br />
                    <Heading text={'1.7 Beveiliging van de gegevens'} light={false} subHeading={true}/>
                    <Divider />
                    <p>Volgende passende technische en organisatorische maatregelen zijn genomen om persoonsgegevens te beschermen tegen onrechtmatige
                    verwerking:</p>
                    <br />
                    <ul className='md:ml-16 ml-6'>
                        <li className='list-disc'>Alle personen die namens <b>Robin Hood Boechout</b> van je gegevens kennis kunnen nemen, zijn gehouden aan geheimhouding daarvan.</li>
                        <li className='list-disc'>We hanteren een gebruikersnaam en wachtwoordbeleid op al onze systemen.</li>
                        <li className='list-disc'>We pseudoniemeren en zorgen voor de encryptie van persoonsgegevens als daar aanleiding toe is.</li>
                        <li className='list-disc'>Wij maken back-ups van de persoonsgegevens om deze te kunnen herstellen bij fysieke of technische incidenten.</li>
                        <li className='list-disc'>We testen en evalueren regelmatig onze maatregelen.</li>
                        <li className='list-disc'>Onze medewerkers zijn geïnformeerd over het belang van de bescherming van persoonsgegevens.</li>
                    </ul>
                    <br />
                    <Heading text={'1.8 Je rechten omtrent je gegevens'} light={false} subHeading={true}/>
                    <Divider />
                    <p>Je hebt recht op inzage, kopie, aanpassing of het wissen van de persoonsgegevens die wij ontvangen hebben. Via het hogervermeld adres
                    kun je hier ons hiervoor contacteren.
                    <br /><br />
                    Tevens kan je bezwaar indienen tegen de verwerking van je persoonsgegevens (of een deel hiervan) door ons of door één van onze verwerkers.
                    <br /><br />
                    Ook heb je het recht om de verstrekte gegevens door ons te laten overdragen aan jezelf of in jouw opdracht direct aan een andere partij. Wij
                    kunnen je vragen om je te legitimeren voordat wij gehoor kunnen geven aan voornoemde verzoeken.</p>
                    <br />
                    <Heading text={'2. Klachten'} light={false} subHeading={false}/>
                    <Divider />
                    <p>Mocht je een klacht hebben over de verwerking van je persoonsgegevens dan vragen wij je hierover direct contact met ons op te nemen.
                    <br /><br />
                    Je hebt altijd het recht een klacht in te dienen bij de Gegevensbeschermingsautoriteit (GBA):
                    <br /><br />
                    Drukpersstraat 35<br />
                    1000 Brussel<br />
                    <a className='text-primary hover:text-secondary' href='https://www.gegevensbeschermingsautoriteit.be' target='_blank'>https://www.gegevensbeschermingsautoriteit.be</a>
                    </p>
                    <br />
                    <Heading text={'3. Wijziging privacyverklaring'} light={false} subHeading={false}/>
                    <Divider />
                    <p><b>Robin Hood Boechout</b> kan de privacyverklaring steeds wijzigen. De laatste wijziging gebeurde op 07/03/2018.</p>
                </div>
            </div>
        </section>
    );
};

export default PrivacyPolicy;