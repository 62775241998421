import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Divider from '../../components/common/Divider';
import InputField from '../../components/common/InputField';
import SectionTitle from '../../components/common/SectionTitle';
import { FaTimes } from 'react-icons/fa';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Login() {
    const [open, setOpen] = useState(false)
    const navigate = useNavigate();
    const [loginData, setLoginData] = useState({ email: '', password: '', });
    const [requestData, setRequestData] = useState({ email: '' });

    const handleChangeLogin = (name: string, value: string) => {
        setLoginData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleChangeRequest = (name: string, value: string) => {
        setRequestData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleRequest = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://api.robinhoodboechout.be/api/v1/auth/request', requestData, {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });

            if (response.status === 201) {
                setOpen(false);
                toast.success('Uw toegang is succesvol aangevraagd.', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Bounce,
                });
            }

        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response && error.response.status === 409) {
                    toast.warn('Er is al toegang aangevraagd voor dit email adres.', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        transition: Bounce,
                    });
                }
            } else {
                console.error(error);
                toast.error('Er is iets misgegaan. Probeer het later opnieuw.', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Bounce,
                });
            }
        }
    }

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://api.robinhoodboechout.be/api/v1/auth/login', loginData, {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });
            if (response.status === 200) {
                sessionStorage.setItem('access_token', response.data.token);
                navigate('/admin/dashboard');
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response && error.response.status === 401) {
                    toast.error('Het opgegeven email en/of paswoord is incorrect.', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        transition: Bounce,
                    });
                }
            } else {
                console.error(error);
                toast.error('Er is iets misgegaan. Probeer het later opnieuw.', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Bounce,
                });
            }
        }

    }

    return (
        <>
            <div className='min-h-screen flex flex-col items-center justify-center py-6 px-4'>
                <div className='grid md:grid-cols-2 items-center gap-10 max-w-6xl w-full'>
                    <div>
                        <h2 className='lg:text-5xl text-4xl font-extrabold lg:leading-[55px] text-gray-800'>
                            Beheerder Toegang
                        </h2>
                        <Divider />
                        <p className='text-sm mt-6 text-gray-800'>Dit portaal biedt u de mogelijkheid om het Content Management System van Robin Hood Boechout te gebruiken. Hiermee kunt u eenvoudig de inhoud van de website creëren en bewerken.</p>
                        <p className='text-sm mt-6 text-gray-800'>Denkt u toegang nodig te hebben tot dit portaal? <a onClick={() => setOpen(true)} className='text-secondary font-semibold hover:underline ml-1 cursor-pointer'>Vraag nu toegang aan!</a></p>
                    </div>

                    {/* SIGNIN */}
                    <form className='max-w-md md:ml-auto w-full' onSubmit={handleLogin}>
                        <div className='mb-8'>
                            <SectionTitle text={'Inloggen'} light={false} />
                            <Divider />
                        </div>

                        <div className='space-y-4'>
                            <InputField name={'email'} type={'email'} required={true} placeholder={'Email Address'} value={loginData.email} onChange={handleChangeLogin} />
                            <InputField name={'password'} type={'password'} required={true} placeholder={'Password'} value={loginData.password} onChange={handleChangeLogin} />
                        </div>

                        <div className='!mt-8'>
                            <button type='submit' className='w-full shadow-xl py-2.5 px-4 text-sm font-semibold rounded text-white bg-primary hover:bg-secondary focus:outline-none'>
                                Inloggen
                            </button>
                            <ToastContainer />
                        </div>
                    </form>

                    {/* REQUEST MODAL */}
                    <div onClick={() => setOpen(false)} className={`fixed inset-0 flex justify-center items-center transition-colors ${open ? "visible bg-black/20" : "invisible"}`}>
                        <div onClick={(e) => e.stopPropagation()} className={`bg-white rounded-xl shadow p-6 transition-all ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}`}>
                            <button onClick={() => setOpen(false)} className="absolute top-2 right-2 p-1 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600">
                                <FaTimes />
                            </button>

                            <form className='max-w-md md:ml-auto w-full' onSubmit={handleRequest}>
                                <div className='mb-8'>
                                    <SectionTitle text={'Toegang Aanvragen'} light={false} />
                                    <Divider />
                                </div>

                                <div className='space-y-4'>
                                    <InputField name={'email'} type={'email'} required={true} placeholder={'Email Address'} value={requestData.email} onChange={handleChangeRequest} />
                                </div>

                                <div className='!mt-8'>
                                    <button type='submit' className='w-full shadow-xl py-2.5 px-4 text-sm font-semibold rounded text-white bg-primary hover:bg-secondary focus:outline-none'>
                                        Aanvragen
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}