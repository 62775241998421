import Banner from '../components/layout/Banner';
import Footer from '../components/layout/Footer';
import PrivacyPolicy from '../components/layout/footer/PrivacyPolicy';

import BackgroundImage from '../assets/images/trainingen.jpg';

export default function Privacy() {
    return (
        <>
            <Banner title={'Privacy'} image={BackgroundImage} />
            <PrivacyPolicy />
            <Footer />
        </>
    );
}