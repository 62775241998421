import Divider from '../../common/Divider';
import SectionTitle from '../../common/SectionTitle';
import StaffCard from '../../common/StaffCard';

export default function Trainers() {
    return (
        <>
            <section className='py-8 flex flex-col items-center'>
                <SectionTitle text={'Trainers & Initiators'} light={false} />
                <Divider />
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-10 md:gap-40 py-8'>
                    <StaffCard name={''} role={'Trainer / Coach'} picture={''} email={''} />
                    <StaffCard name={''} role={'Initiator'} picture={''} email={''} />
                    <StaffCard name={''} role={'Initiator'} picture={''} email={''} />
                    <StaffCard name={''} role={'Initiator'} picture={''} email={''} />
                </div>
            </section>
        </>
    );
}