import { useParams } from 'react-router-dom';
import Footer from '../components/layout/Footer';

import PostContent from '../components/layout/nieuws/post-content/PostContent';
import Navbar from '../components/layout/Navbar';

export default function PostPage() {
    const { slug } = useParams();
    return (
        <>
            <div className='bg-[rgba(0,0,0,0.5)]'>
                <Navbar />
            </div>
            <PostContent slug={slug} />
            <Footer />
        </>
    );
}