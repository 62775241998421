import React from 'react';
import './App.css';
import './datatable.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

// Import Pages
import Home from './pages';
import Trainingen from './pages/trainingen';
import Luchtdoelschieten from './pages/luchtdoelschieten';
import Nieuws from './pages/nieuws';
import Agenda from './pages/agenda';
import Lidgelden from './pages/lidgelden';
import PostPage from './pages/postPage';
import Privacy from './pages/privacy';
import SeksueleIntimidatie from './pages/seksueleIntimidatie';
import Gedragscode from './pages/gedragscode';
import Reglement from './pages/reglement';

// Admin Pages
import Admin from './pages/admin/admin';
import Dashboard from './pages/admin/dashboard';
import AdminNieuwsberichten from './pages/admin/nieuwsberichten/nieuwsberichten';
import AdminNieuwsbrief from './pages/admin/nieuwsbrief';
import AdminBestuur from './pages/admin/bestuur';
import AdminTrainingen from './pages/admin/trainingen';
import AdminEvenementen from './pages/admin/evenementen';
import AdminLidgeld from './pages/admin/lidgeld';
import AdminFotos from './pages/admin/fotos';
import AdminGebruikers from './pages/admin/gebruikers';
import AdminLegal from './pages/admin/legal';

// Authentication Logic & Dashboard Styling
import ProtectedRoute from './components/ProtectedRoute';
import ProtectedLayout from './components/ProtectedLayout';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {/* Main Pages */}
        <Route path='/' element={<Home />} />
        <Route path='/trainingen' element={<Trainingen />} />
        <Route path='/luchtdoelschieten' element={<Luchtdoelschieten />} />
        <Route path='/nieuws' element={<Nieuws />} />
        <Route path='/nieuws/:slug' element={<PostPage />} />
        <Route path='/agenda' element={<Agenda />} />
        <Route path='/lidgelden' element={<Lidgelden />} />

        {/* Footer Navigation */}
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/seksuele-intimidatie' element={<SeksueleIntimidatie />} />
        <Route path='/gedragscode' element={<Gedragscode />} />
        <Route path='/reglement' element={<Reglement />} />

        {/* Authentication */}
        <Route path='/admin' element={<Admin />} />

        {/* Protected Routes */}
        <Route element={<ProtectedRoute />}>
          <Route element={<ProtectedLayout />}>
            <Route path="/admin/dashboard" element={<Dashboard />} />
            <Route path="/admin/nieuwsberichten" element={<AdminNieuwsberichten />} />
            <Route path="/admin/nieuwsbrief" element={<AdminNieuwsbrief />} />
            <Route path="/admin/bestuur" element={<AdminBestuur />} />
            <Route path="/admin/trainingen" element={<AdminTrainingen />} />
            <Route path="/admin/evenementen" element={<AdminEvenementen />} />
            <Route path="/admin/lidgeld" element={<AdminLidgeld />} />
            <Route path="/admin/fotos" element={<AdminFotos />} />
            <Route path="/admin/gebruikers" element={<AdminGebruikers />} />
            <Route path="/admin/legal" element={<AdminLegal />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
