import Banner from '../components/layout/Banner';
import Footer from '../components/layout/Footer';
import ClubReglement from '../components/layout/footer/ClubReglement';

import BackgroundImage from '../assets/images/trainingen.jpg';

export default function Reglement() {
    return (
        <>
            <Banner title={'Club Reglement'} image={BackgroundImage} />
            <ClubReglement />
            <Footer />
        </>
    );
}