import Divider from "../../common/Divider";
import SectionTitle from "../../common/SectionTitle";
import DataTable from "../../common/DataTable";
import Button from '../../common/Button';

const Info = () => {

    return (
        <section className='container text-center mx-auto grid grid-cols-1 md:grid-cols-2 py-24 p-10'>
            <div className="w-full flex flex-col items-center md:items-start md:text-left md:block">
                <SectionTitle text={"Onderdelen Lidgeld:"} light={false} />
                <Divider />
                <p className="py-4">
                    Het lidgeld bestaat uit een bijdrage voor Boogsport Vlaanderen & een bijdrage voor de club:
                </p>
                <SectionTitle text={"Bijdrage Boogsport Vlaanderen:"} light={false} />
                <Divider />
                <DataTable
                    heading={['', 'Normaal Tarief', 'Nieuwe Leden (01/03 - 30/09)']} 
                    rows={[
                        ['Regulier lidmaatschap', '€ 60,-', '€ 30,-'],
                        ['Pupillen & Benjamins (t.e.m het jaar dat men 14 wordt)', '€ 43,-', '€ 22,-'],
                        ['Familietarief', '€ 80,-', '/']
                    ]} 
                />
                <SectionTitle text={"Bijdrage voor de Club:"} light={false} />
                <Divider />
                <DataTable
                    heading={['', 'Normaal Tarief', 'Nieuwe Leden (01/03 - 30/09)']} 
                    rows={[
                        ['Volwassenen', '€ 60,-', '€ 30,-'],
                        ['Tweede & volgende volwassenen binnen hetzelfde gezin', '€ 40,-', '€ 20,-'],
                        ['Jeugd of Student tot 25 jaar', '€ 40,-', '€ 20,-'],
                        ['Tweede & volgende jeugd/student binnen hetzelfde gezin', '€ 20,-', '€ 10,-']
                    ]} 
                />
                <SectionTitle text={"(Optioneel) Huur Boog:"} light={false} />
                <Divider />
                <DataTable
                    heading={['', 'Normaal Tarief', 'Nieuwe Leden (01/03 - 30/09)']} 
                    rows={[
                        ['Jeugd', '€ 60,-', '€ 30,-'],
                        ['Volwassenen', '€ 70,-', '€ 35,-']
                    ]} 
                />
                <SectionTitle text={"(Optioneel) Antwerpse Federatie:"} light={false} />
                <Divider />
                <DataTable
                    heading={['', 'Normaal Tarief', 'Nieuwe Leden (01/03 - 30/09)']} 
                    rows={[
                        ['Lidgeld', '€ 3,-', '€ 3,-']
                    ]} 
                />
            </div>
            <div className="w-full">
                <Button className='block md:hidden' text={'Lid worden'} link={'https://www.mijnassist.be/NL/389eab02-d01a-48d9-ba2e-1f2925de63a6/waitinglist/subscribe'} />
                <iframe className='w-full h-full hidden md:block' src="https://www.mijnassist.be/NL/389eab02-d01a-48d9-ba2e-1f2925de63a6/waitinglist/subscribe"></iframe>
            </div>
        </section>
    );
};

export default Info;