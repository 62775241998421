import React from 'react';

interface SubTitleProps {
    text: string;
}

const SubTitle: React.FC<SubTitleProps> = ({ text }) => {
    return (
        <h3 className='text-primary-text font-bold uppercase md:text-2xl text-center mx-auto'>{text}</h3>
    );
};

export default SubTitle;