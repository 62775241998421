import Divider from '../../common/Divider';
import SectionTitle from '../../common/SectionTitle';
import StaffCard from '../../common/StaffCard';



const Team = () => {

    return (
        <section className='py-16 flex flex-col items-center'>
            <SectionTitle text={'Sport Bestuur'} light={false} />
            <Divider />
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-10 py-8'>
                {/* 2x4 bestuur leden */}
                <StaffCard name={''} role={'Voorzitter'} picture={''} email={''} />
                <StaffCard name={''} role={'Ondervoorzitter'} picture={''} email={''} />
                <StaffCard name={''} role={'Secretaris'} picture={''} email={''} />
                <StaffCard name={''} role={'Penningmeester'} picture={''} email={''} />
                <StaffCard name={''} role={'Leden Beheer'} picture={''} email={''} />
                <StaffCard name={''} role={'Materiaalmeester'} picture={''} email={''} />
                <StaffCard name={''} role={'Jeugdbegeleider'} picture={''} email={''} />
                <StaffCard name={''} role={'Webmaster'} picture={''} email={''} />
            </div>

            <SectionTitle text={'VZW Bestuur'} light={false} />
            <Divider />
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 pt-8'>
                {/* 1x3 VZW leden */}
                <StaffCard name={''} role={'Voorzitter'} picture={''} />
                <StaffCard name={''} role={'Penningmeester'} picture={''} />
                <StaffCard name={''} role={'Secretaris'} picture={''} />
            </div>

        </section>
    );
};

export default Team;