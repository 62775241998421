import Divider from '../../common/Divider';
import Heading from '../../common/Heading';


const ProtocolSeksueleIntimidatie = () => {

    return (
        <section className='py-4 flex flex-col items-center'>
            <div className='container md:w-[60%]'>
                <div className='p-8 whitespace-pre-wrap md:text-left md:items-left'>
                    <Heading text={'Protocol tegen seksuele intimidatie'} light={false} subHeading={false} />
                    <Divider />
                    <p>
                    In dit document heeft het bestuur van handboogvereniging <b>Robin Hood Boechout</b> vastgelegd
                    hoe er gewenstgedrag wordt gestimuleerd, risico situaties te mijden en seksuele intimidatie
                    binnen de vereniging te voorkomen.
                    </p>
                    <br />
                    <Heading text={'Gewenste omgang bevorderen'} light={false} subHeading={true} />
                    <Divider />
                    <p>
                    <b>Handboogschutters</b>
                        <br />
                        <br />
                        Het is belangrijk dat leden zich veilig voelen binnen hun sportvereniging. Hier hoort bij dat
                        zij zich niet seksueel geïntimideerd mogen worden. Om het risico daarop zo klein mogelijk te
                        maken zijn er een aantalomgangsregels opgesteld.
                    </p>
                    <br />
                    <ul className='md:ml-16 ml-6'>
                        <li className="list-disc">Ik accepteer en respecteer de ander zoals hij is en discrimineer niet. Iedereen telt mee binnen devereniging</li>
                        <li className="list-disc">Ik houd rekening met de grenzen die de ander aangeeft</li>
                        <li className="list-disc">Ik val de ander niet lastig</li>
                        <li className="list-disc">Ik berokken geen schade aan de ander</li>
                        <li className="list-disc">Ik maak op geen enkele wijze misbruik van mijn machtpositie</li>
                        <li className="list-disc">Ik scheld niet en maak geen gemene grappen of opmerkingen over de ander</li>
                        <li className="list-disc">Ik negeer een ander niet</li>
                        <li className="list-disc">Ik doe niet mee aan pesten, uitlachen en roddelen</li>
                        <li className="list-disc">Ik vecht niet, ik gebruik geen geweld, ik bedreig een ander niet, ik neem, met uitzondering van mijnhandboog, geen andere wapens mee</li>
                        <li className="list-disc">Ik kom niet ongewenst te dichtbij en raak de ander niet tegen zijn of haar wil aan</li>
                        <li className="list-disc">Ik geef de ander geen ongewenste seksueel getinte aandacht</li>
                        <li className="list-disc">Ik stel geen ongepaste vragen en maak geen ongewenste opmerkingen over iemands persoonlijk levenof uiterlijk</li>
                        <li className="list-disc">Wanneer iemand mij hindert of lastig valt dan vraag ik hem / haar hiermee te stoppen, wanneer dit niethelpt vraag ik een ander om hulp</li>
                        <li className="list-disc">Ik help anderen om zich ook aan deze afspraken te houden en spreek degene aan die zich daar niet aanhoudt erop aan en meldt dit zo nodig bij het bestuur</li>
                    </ul>
                    <br />
                    <p>
                    Bovenstaande regels worden bij inschrijving bekend gemaakt aan de leden en zij dienen
                    akkoord te gaanvoordat ze zich in kunnen schrijven als lid. Tevens zijn deze regels te
                    vinden op de website <a className='text-primary hover:text-secondary' href='https://robinhoodboechout.be/'>https://robinhoodboechout.be/</a>. Op de site wordt ook informatie
                    gegeven over de vertrouwenspersoon(VCP). Het gaat daarbij om zowel een
                    taakomschrijving als de contactgegevens. Aan de trainer, bestuur en ouders wordt gevraagd
                    om ongewenst gedrag bij de VCP te melden wanneer zij dit tegenkomen of vermoeden.
                    <br />
                    <br />
                    <b>Trainer / Begeleider</b>
                    <br />
                    <br />
                    Naast de algemene omgangsregels zijn er aanvullende regels voor de trainer/ begeleider en anderen
                    die eenactieve rol spelen rondom de leden schutters. ( hierna genoemd begeleider)
                    <br />
                    <br />
                    Het gaat om de volgende regels:
                    </p>
                    <br />
                    <ul className='md:ml-16 ml-6'>
                        <li className="list-disc">De begeleider zorgt voor een omgeving en een sfeer waarbinnen de schutters zich veilig kan
                        voelen</li>
                        <li className="list-disc">De begeleider onthoudt zich ervan schut(s)ters te bejegenen op een wijze die de
                        schut(s)ter in zijn/haarwaardigheid aantast, en verder in het privé-leven van de schut(s)ter
                        door te dringen dan nodig is in het kader van de sportbeoefening</li>
                        <li className="list-disc">De begeleider onthoudt zich ervan elke vorm (machts)misbruik of seksuele intimidatie
                        tegenover deschut(s)ters</li>
                        <li className="list-disc">Seksuele handelingen en seksuele relaties tussen begeleider en de schut(s)ter tot 16 jaar
                        zijn onder geenbeding geoorloofd en worden beschouwd als seksueel misbruik</li>
                        <li className="list-disc">De begeleider mag de schut(s)ter niet op een zodanige wijze aanraken dat ze deze
                        aanraking naar redelijke verwachting als seksueel of erotisch van aard zal ervaren, zoals
                        doorgaans het geval zal zijn bijhet doelbewust (doen) aanraken van geslachtdelen, billen en
                        borsten</li>
                        <li className="list-disc">De begeleider onthoudt zich van (verbale) seksueel getinte intimiteiten via welk
                        communicatiemiddeldan ook</li>
                        <li className="list-disc">De begeleider zal tijdens de training, wedstrijden en reizen gereserveerd en met respect
                        omgaan met deschut(s)ters en met de ruimte waarin deze zich bevindt, zoals kleedkamer
                        of hotelkamer</li>
                        <li className="list-disc">De begeleider heeft de plicht -voor zover in zijn vermogen ligt- de schut(s)ter te
                        beschermen tegen schade en (machts) misbruik als gevolg van seksuele intimidatie. Daar
                        waar bekend of geregeld is wiede belangen van de schut(s)ter behartigt, is de begeleider
                        verplicht met deze personen of instanties samen te werken, opdat zij hun werk goed uit
                        kunnen oefenen</li>
                        <li className="list-disc">De begeleider zal de schut(s)ter geen (im) materiële vergoedingen geven met de
                        kennelijke bedoelingtegenprestaties te vragen. Ook de begeleider aanvaardt geen
                        financiële beloning of geschenken van deschut(s)ter die in een onevenredige verhouding
                        tot de gebruikelijke dan wel afgesproken honorering staan</li>
                        <li className="list-disc">De begeleider zal er actief op toe zien dat deze regels worden nageleefd door iedereen
                        die bij de schut(s)ters is betrokken. Indien de begeleider gedrag signaleert dat niet in
                        overeenstemming is metdeze gedragsregels zal hij de daartoe noodzakelijke actie(s)
                        ondernemen</li>
                        <li className="list-disc">In die gevallen waarin deze regels niet (direct) voorzien, ligt het binnen de verantwoordelijkheid van debegeleider in de geest hiervan te handelen</li>
                    </ul>
                    <br />
                    <p>
                    Tevens wordt er van alle begeleiders gevraagd een Verklaring Omtrent gedrag (VOG) te overleggen
                    en controleert de vereniging of zij zijn opgenomen in het registratiesysteem voor plegers seksuele
                    intimidatie.
                    </p>
                    <br />
                    <Heading text={'Situaties van seksuele intimidatie oplossen'} light={false} subHeading={true} />
                    <Divider />
                    <p>
                    Wanneer er zich een situatie voordoet waarin een lid zich seksueel geïntimideerd voelt kan deze
                    terecht bij de vertrouwenspersoon (VCP) van de vereniging <b>Robin Hood Boechout</b>.
                    <br />
                    <br />
                    Deze is te bereiken via <a className='text-primary hover:text-secondary' href='mailto:marleen.sanchez@robinhoodboechout.be'>marleen.sanchez@robinhoodboechout.be</a>, of men kan contact opnemen
                    met de vertrouwenspersoon van Boogsport Vlaanderen. Deze is te bereiken via <a className='text-primary hover:text-secondary' href='mailto:info@boogsport.vlaanderen'>info@boogsport.vlaanderen</a> of
                    014 86 93 87.
                    <br />
                    <br />
                    <i>Deze personen zijn opgeleid om dergelijke uiteenlopende situaties in te schatten en de bijhorendeprocedures testarten en te begeleiden.</i>
                    </p>
                    <br />
                    <Heading text={'Sancties'} light={false} subHeading={true} />
                    <Divider />
                    <p>
                    Een aantal van de sancties zijn voor de vereniging extern, denk hierbij aan een vervolging voor
                    overtreding vanartikelen uit het Wetboek van Strafrecht. Wel kunnen interne sancties na overleg
                    met de VCP door het bestuur opgelegd worden. Het bestuur stelt hierbij het collectieve belang van
                    haar leden boven eventueel individueel belang van het lid.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default ProtocolSeksueleIntimidatie;