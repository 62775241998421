import Banner from '../components/layout/Banner';
import Footer from '../components/layout/Footer';
import Info from '../components/layout/lidgelden/info';

import BackgroundImage from '../assets/images/trainingen.jpg';
  
export default function Lidgelden() {
    return (
        <>
            <Banner title={'Word lid van ons!'} image={BackgroundImage} />
            <Info />
            <Footer />
        </>
    );
}