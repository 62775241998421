import Banner from '../components/layout/Banner';
import Footer from '../components/layout/Footer';
import GedragsCode from '../components/layout/footer/GedragsCode';

import BackgroundImage from '../assets/images/trainingen.jpg';


export default function Gedragscode() {
    return (
        <>
            <Banner title={'Gedragscode'} image={BackgroundImage} />
            <GedragsCode />
            <Footer />
        </>
    );
}