import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface DateTimeObject {
    date: string;
    time: string;
}

interface CalendarEvent {
    date: string;
    start: DateTimeObject;
    end: DateTimeObject;
    details: {
        title: string;
        description?: string;
    };
    link: string;
}

interface GoogleCalendarProps {
    calendar: string;
}

const GoogleCalendar: React.FC<GoogleCalendarProps> = ({ calendar }) => {
    const [events, setEvents] = useState<CalendarEvent[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchEvents = async () => {
            // Fetching Calendar Events from backend.
            try {
                const response = await axios.get(`http://localhost:443/api/v1/events/${calendar}`);
                setEvents(response.data);
            } catch (error) {
                setError('Failed to fetch events');
                console.error(error);
            }
        };

        fetchEvents();
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <div className='flex flex-col'>
                <div className='-m-1.5 overflow-auto max-h-[50vh]'>
                    <div className='p-1.5 min-w-full inline-block align-middle'>
                        <div className='overflow-hidden'>
                            {events.length > 0 ? (
                            <table className='min-w-full divide-y divide-gray-200'>
                                <thead>
                                    <tr>
                                        <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase'>Datum</th>
                                        <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase'>Start</th>
                                        <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase'>Details</th>
                                        <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase'>Link</th>
                                    </tr>
                                </thead>
                                <tbody>
                                
                                    {events.map((event, index) => (
                                        <tr key={index} className='odd:bg-white even:bg-gray-100 '>
                                            <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 '>{event.start.date}</td>
                                            <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 '>{event.start.time}</td>
                                            <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 text-wrap'>{event.details.title}</td>
                                            <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-secondary '>
                                                <a href={event.link} target='_blank'>Open Google Agenda</a>
                                            </td>
                                        </tr>
                                    ))}
                                
                                </tbody>
                            </table>
                            ): (
                                <p>Er zijn momenteel geen sessies gepland.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default GoogleCalendar;