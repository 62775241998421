import Divider from '../../common/Divider';
import Heading from '../../common/Heading';
import { useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from '../../common/DataTable';

interface TrainingsProps {
    training_id: number;
    day: string;
    hours: string;
    details: string;
    season: string;
}

const ClubReglement = () => {
    const [winterTrainings, setWinterTrainings] = useState<TrainingsProps[]>([]);
    const [summerTrainings, setSummerTrainings] = useState<TrainingsProps[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchTrainings = async () => {
            try {
                const response = await axios.get(`https://api.robinhoodboechout.be/api/v1/trainings`);
                let winter: TrainingsProps[] = [];
                let summer: TrainingsProps[] = [];

                response.data.forEach((training: TrainingsProps) => {
                    if (training.season === 'winter') winter.push(training);
                    else summer.push(training);
                });
                setWinterTrainings(winter);
                setSummerTrainings(summer);
            } catch (error) {
                setError('Failed to fetch training details');
                console.error(error);
            }
        };

        fetchTrainings();
    }, []);

    return (
        <section className='py-4 flex flex-col items-center'>
            <div className='container md:w-[60%]'>
                <div className='p-8 whitespace-pre-wrap md:text-left md:items-left'>
                    <Heading text={'Voorwoord'} light={false} subHeading={false} />
                    <Divider />
                    <p>
                        Beste,
                        <br />
                        <br />
                        De Schuttersvereniging Robin Hood, gekend als Robin Hood Boechout of kortweg RHB, is een
                        Vereniging Zonder Winstoogmerk (vzw). Dit wil zeggen dat alle inkomsten kostendekkend zijn of
                        worden geïnvesteerd in de club. Verder biedt een vzw een eenvoudige vorm van rechtszekerheid.
                        <br />
                        <br />
                        De raad van bestuur van de vzw heeft het sportbestuur van RHB opgedragen de dagelijkse werking van
                        de club te organiseren, hierbij waakt de raad van bestuur over de sportiviteit en de doelstellingen van
                        de club:<br />
                        RHB heeft tot doel het handboogschieten te beoefenen en te organiseren, wedstrijden in te richten en
                        het handboogschieten te propageren en bekend te maken als een sport met hoge opvoedende en
                        recreatieve waarde.
                        <br />
                        <br />
                        Zoals het hoort in elke club, heeft het sportbestuur dit huishoudelijk reglement opgesteld om de
                        werking van de club, de trainingen en wedstrijden in goede banen te leiden, alsook misverstanden en
                        onnodige problemen te voorkomen. Waar deze reglementen aanvullend zijn, komt het er op aan om
                        als boogschutter je verstand te gebruiken en elkaar met respect te behandelen.
                        <br />
                        <br />
                        Alvast bedankt voor uw medewerking!
                        <br />
                        <br />
                        Met sportieve groeten,<br />
                        Het bestuur.
                    </p>
                    <br />
                    <br />
                    <Heading text={'Het Bestuur'} light={false} subHeading={false} />
                    <Divider />
                    <div className='space-y-2 w-1/2'>
                        <div className='flex flex-col sm:flex-row justify-between'>
                            <span><strong>Voorzitter:</strong></span>
                            <span>Mark Wouters</span>
                        </div>
                        <div className='flex flex-col sm:flex-row justify-between'>
                            <span><strong>Ondervoorzitter:</strong></span>
                            <span>Marleen Sanchez</span>
                        </div>
                        <div className='flex flex-col sm:flex-row justify-between'>
                            <span><strong>Secretaris:</strong></span>
                            <span>Eza Capelle</span>
                        </div>
                        <div className='flex flex-col sm:flex-row justify-between'>
                            <span><strong>Jeugdbegeleider:</strong></span>
                            <span>Gezocht</span>
                        </div>
                        <div className='flex flex-col sm:flex-row justify-between'>
                            <span><strong>Ledenbeheer:</strong></span>
                            <span>Tony Van Havere</span>
                        </div>
                        <div className='flex flex-col sm:flex-row justify-between'>
                            <span><strong>Materiaalverantwoordelijke:</strong></span>
                            <span>Ulrik Van Oosterwijck</span>
                        </div>
                        <div className='flex flex-col sm:flex-row justify-between'>
                            <span><strong>Webmaster:</strong></span>
                            <span>Yorick Cleerbout</span>
                        </div>
                        <div className='flex flex-col sm:flex-row justify-between'>
                            <span><strong>Trainer:</strong></span>
                            <span>Tom Markey</span>
                        </div>
                    </div>
                    <br />
                    <br />
                    <Heading text={'De Trainingen'} light={false} subHeading={false} />
                    <Divider />
                    {error &&
                        <p>Er is een fout opgetreden tijdens het ophalen van trainingen.</p>
                    }
                    {winterTrainings &&
                        <>
                            <Heading text={'In de Winter'} light={false} subHeading={true} />
                            <Divider />
                            <div className='space-y-2 w-1/2'>
                                {
                                    winterTrainings.map((object, index) => (
                                        <div key={index} className='flex flex-col sm:flex-row justify-between'>
                                            <span><strong>{object.day}</strong></span>
                                            <span>{object.hours}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    }
                    <br />
                    <br />
                    {summerTrainings &&
                        <>
                            <Heading text={'In de Zomer'} light={false} subHeading={true} />
                            <Divider />
                            <div className='space-y-2 w-1/2'>
                                {
                                    summerTrainings.map((object, index) => (
                                        <div key={index} className='flex flex-col sm:flex-row justify-between'>
                                            <span><strong>{object.day}</strong></span>
                                            <span>{object.hours}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    }
                    <br />
                    <br />
                    <Heading text={'Gedragsregels van een schutter'} light={false} subHeading={false} />
                    <Divider />
                    <p>
                        Wanneer men aan de schietlijn staat, zowel op training, thuis, in de club als op wedstrijden, zal men
                        volgende regels spontaan moeten uitvoeren:
                    </p>
                    <br />
                    <ul className='md:ml-16 ml-6'>
                        <li className='list-disc'>
                            Aan de schietlijn sta je altijd met het aangezicht richting doel.
                        </li>
                        <li className='list-disc'>
                            Aan de schietlijn wordt niet gepraat.
                        </li>
                        <li className='list-disc'>
                            De pijlen zitten altijd in een pijlenkoker of pijlentas vooraleer men aan de lijn staat.
                        </li>
                        <li className='list-disc'>
                            Steek nooit een pijl op je boog als je niet aan de lijn staat.
                        </li>
                        <li className='list-disc'>
                            Steek nooit een pijl op je boog als er nog iemand zich voor de schietlijn bevindt.
                        </li>
                        <li className='list-disc'>
                            Richt een geladen boog steeds naar het doel.
                        </li>
                        <li className='list-disc'>
                            Hou voldoende afstand tussen de schutters aan de lijn zodat iedereen ruimte genoeg heeft om
                            zijn schot uit te voeren. Er moet minimaal 75cm voor een schutter ter beschikking zijn aan de
                            lijn.
                        </li>
                        <li className='list-disc'>Blijf aan de lijn steeds rustig, je hebt immers een wapen vast. </li>
                        <li className='list-disc'>Controleer na elke schietbeurt of uw pijlen niet beschadigd zijn, vraag desgevallend uw trainer
                            waar je moet op letten. </li>
                        <li className='list-disc'>Aanvaard steeds de instructies van uw trainer of verantwoordelijke. </li>
                        <li className='list-disc'>Ga nooit te kort achter de schutters staan als ze nog aan het schieten zijn. Respecteer hier de
                            veilige afstand zodat de schutter naar behoren het schot kan uitvoeren en indien nodig de
                            trainer makkelijk tot zijn leerling kan komen. De veilige afstand bedraagt minimaal 2 meter
                            achter de schietlijn.</li>
                        <li className='list-disc'>Schep niet op maar moedig elkaar aan.</li>
                        <li className='list-disc'>Als je twijfelt over iets, ga dan steeds ten rade bij uw trainer of verantwoordelijke. </li>
                        <li className='list-disc'>Loop nooit op de schietstand. </li>
                    </ul>
                    <br />
                    Regels specifiek voor de binnenstand:
                    <br />
                    <br />
                    <ul className='md:ml-16 ml-6'>
                        <li className='list-disc'>Hou alle schietlijnen zo goed mogelijk bezet, dus geen 4 lijnen van 3 schutters maar 2 lijnen
                            van 6 schutters. Gebruik van het bord is hierbij efficiënt en aangewezen. </li>
                        <li className='list-disc'>Schrijf je naam op het bord als je schietensklaar bent. Dit is de plaats die je die training zal
                            schieten. Stop je voor meerdere beurten, veeg dan je naam van het bord en zet je boog achter
                            de 25m lijn. Als je even wil praten met clubgenoten, doe dit dan ook achter de 25m lijn. Veeg
                            ook je naam van het bord als je stopt met schieten. </li>
                        <li className='list-disc'>Doelen 4 en 5 zijn steeds voorbehouden voor compoundschutters indien zij dit wensen. </li>
                        <li className='list-disc'>Op de blauwe strook komen en enkel de schutters die aan beurt zijn en zij die deze schutters
                            trainen of in het oog houden.</li>
                        <li className='list-disc'>Ben je een goede schutter, gebruik dan een 3-spot. Het is beter voor de doelen en uw nokken.
                            Topwedstrijden zijn ook steeds op 3-spot. </li>
                    </ul>
                    <br />
                    <br />
                    <Heading text={'Algemeen reglement'} light={false} subHeading={false} />
                    <Divider />
                    <br />
                    <Heading text={'Onze club en de VZW'} light={false} subHeading={true} />
                    <Divider />
                    <p>
                        De correcte benaming van onze sportclub is <b>'Schuttersvereniging Robin Hood VZW'</b>, kortweg
                        genoemd <b>'Robin Hood'</b>. De vzw heeft tot doel de beoefenaars van het boogschieten te organiseren,
                        wedstrijden in te richten en het boogschieten te propageren en bekend te maken.
                        <br />
                        <br />
                        De vzw heeft een sportbestuur, dat bestaat uit toegetreden leden. Zij runnen de club, zij nemen leden
                        aan (toetredende leden), organiseren, bepalen de hoogte van het lidgeld, reglementeren, richten
                        dingen in, proberen de rekeningen te laten kloppen enz. Kortom, zij staan in voor het dagelijks bestuur
                        van de club. Alle toegetreden leden kunnen zich kandidaat stellen voor leidende functies binnen het
                        sportbestuur. De statuten van de vzw kunnen ten allen tijde door iedereen in de burelen van
                        Capenberg geraadpleegd worden tijdens de openingsuren van het bureel.
                    </p>
                    <br />
                    <Heading text={'Het sportbestuur'} light={false} subHeading={true} />
                    <Divider />
                    <p>
                        Het sportbestuur bestaat minimaal uit 3 personen.
                        <ul className='md:ml-16 ml-6'>
                            <li className='list-disc'>Voorzitter</li>
                            <li className='list-disc'>Secretaris</li>
                            <li className='list-disc'>Ondervoorzitter</li>
                        </ul>
                        <br />
                        Dit bestuur kan aangevuld worden met:
                        <ul className='md:ml-16 ml-6'>
                            <li className='list-disc'>Jeugdverantwoordelijke</li>
                            <li className='list-disc'>Materiaalverantwoordelijke</li>
                            <li className='list-disc'>Ledenbeheer</li>
                            <li className='list-disc'>Trainer</li>
                            <li className='list-disc'>Webmaster</li>
                        </ul>
                        <br />
                        Indien door ontslagneming het bestuur onder het minimale getal van drie bestuursleden komt, zullen
                        de overblijvende bestuursleden dit kenbaar maken aan de vereniging. Er zal aan de leden gevraagd
                        worden om zich kandidaat te stellen voor de open gekomen functies. Intussen zullen de overblijvende
                        bestuursleden waken over de continuïteit van de club. Kandidaat bestuursleden moeten zich aan het
                        bestuur kenbaar maken één maand voor de algemene vergadering. De taken van het bestuur kunnen
                        enkel uitgevoerd worden door volwaardige clubleden.
                    </p>
                    <br />
                    <Heading text={'Soorten leden'} light={false} subHeading={true} />
                    <Divider />
                    <b>Gewone leden</b>
                    <ul className='md:ml-16 ml-6'>
                        <li className='list-disc'><u>gewoon lid schutter:</u> volwassene, ouder dan 18 jaar (schietend) met stemrecht</li>
                        <li className='list-disc'><u>gewoon lid niet-schutter-met-stemrecht:</u> ouder dan 18 jaar (niet-schietend) met stemrecht</li>
                        <li className='list-disc'><u>jeugdlid:</u> jongere, jonger dan 18 jaar (schietend) zonder stemrecht</li>
                        <li className='list-disc'><u>student:</u> volwassene, ouder dan 18 jaar maar nog schoolgaand (schietend) met stemrecht</li>
                    </ul>
                    <br />
                    De hierboven genoemde leden kunnen steeds aangesloten worden bij BSV.
                    <ul className='md:ml-16 ml-6'>
                        <li className='list-disc'><u>sympathisant:</u> een gewoon lid, maar niet schietend; deze persoon kan ook aansluiting bij BSV vragen; dan is hij een gewoon lid</li>
                    </ul>
                    <br />
                    <b>Gastleden</b>
                    <p>Deze personen zijn nog niet aangesloten bij onze club. Elk gastlid zal zijn gegevens noteren in het
                        gastenboek, dit kan tot drie maal. Vervolgens zal gevraagd worden aan te sluiten als gewoon lid. </p>
                    <br />
                    <Heading text={'De jaarlijks algemene vergadering'} light={false} subHeading={true} />
                    <Divider />
                    <p>
                        Vergadering die elk jaar de derde dinsdag van maart plaatsvindt op het adres van de vereniging om
                        20.00h. De exacte datum van die vergadering moet vóór 1 maart aan de leden bekend gemaakt
                        worden.
                        <br />
                        <br />
                        Tijdens deze vergadering komen volgende items verplicht aan bod:
                    </p>
                    <ul className='md:ml-16 ml-6'>
                        <li className='list-disc'>verslag van het voorbije werkingsjaar </li>
                        <li className='list-disc'>uitgebreid kasverslag </li>
                        <li className='list-disc'>begroting van het volgende jaar</li>
                        <li className='list-disc'>bevestiging of aanpassing van reglementen</li>
                        <li className='list-disc'>activiteitenkalender voor het volgende jaar</li>
                        <li className='list-disc'>bepaling hoogte van het lidgeld</li>
                        <li className='list-disc'>verkiezing bestuursleden</li>
                        <li className='list-disc'>varia</li>
                    </ul>
                    <br />
                    <p>
                        Deze vergadering is toegankelijk voor alle leden; de jeugdleden mogen zich mee laten
                        vertegenwoordigen door hun ouders.
                        <br /><br />
                        <b>Verkiesbaar stellen van de bestuursfuncties.</b>
                        <br />
                        Alle functies in het bestuur zijn elke 3 jaar herkiesbaar.
                        <br /><br />
                        <b>Stemmen op de vergadering</b>
                        <br />
                        Er kan alleen gestemd worden door stemgerechtigde leden. Volmachten zijn toegestaan maar elk
                        aanwezig lid kan slechts 1 volmacht hebben. Er wordt alleen gestemd over punten die op de dagorde
                        staan. Stemmen over personen zal steeds anoniem gebeuren. Jongeren hebben geen stemrecht, doch
                        we houden de laatste vrijdag van september een bevraging bij de jeugd, om te weten wat ze wensen,
                        zodat we eventueel hun wensen ook op de agenda van de jaarlijkse vergadering kunnen plaatsen.
                        <br />
                        <br />
                        <b>Continuïteit</b>
                        <br />
                        Om de toekomst van de club niet in gevaar te brengen, zullen de inkomsten van lidgelden, verhuur
                        bogen en eventuele andere inkomsten steeds moeten volstaan om de vaste kosten te dekken, alsook
                        het onderhoud van het materiaal. Met andere woorden, de uitgaven mogen nooit groter zijn dan de
                        inkomsten. Om zekerheid in de toekomst te houden zal het saldo van de rekeningen nooit minder
                        mogen bedragen dan de vaste kosten voor 2 jaar (vaste kosten zijn huur schietstand, aansluiting liga,
                        bankkosten enz.).
                        <br />
                        <br />
                        <b>Gewone ledenvergadering</b>
                        <br />
                        Het bestuur kan steeds een gewone ledenvergadering samenroepen. Dit is een vergadering waarop
                        zaken moeten geregeld worden die een dringende aard hebben en waarvoor een bredere basis nodig
                        is dan enkel het bestuur. Bv grote aankopen, het inrichten van een wedstrijd, ontslaan veelvuldig
                        geschorste leden enz. De leden worden verwittigd via het prikbord, e-mail en mondeling minimaal
                        twee weken voor deze vergadering. Op de gewone ledenvergadering tellen geen volmachten.
                    </p>
                    <br />
                    <Heading text={'Het clubreglement'} light={false} subHeading={true} />
                    <Divider />
                    <p>
                        Het clubreglement zal voor iedereen die de schietstand betreedt ter inzage liggen op de schietstand;
                        iedereen die de schietstand betreedt zal zich ernaar moeten gedragen. Deze regels en bepalingen
                        zorgen voor een goed functioneren van de club en continuïteit in de vereniging. Het bestuur en de
                        leden zijn verplicht zich aan deze regels te houden. Ieder lid zal voor kennisgeving van deze
                        reglementen tekenen, zij kunnen dus geen onwetendheid inroepen.
                        <br /><br />
                        <b>Wijzigingsvoorstellen clubreglement</b>
                        <br />
                        Voorstellen tot wijziging of toevoegingen kunnen worden ingediend door het bestuur of door
                        stemgerechtigde volwassen leden. Zij dienen minstens één maand voor de algemene jaarlijkse
                        vergadering aan de leden kenbaar gemaakt te worden. De voorstellen tot wijziging zullen samen met
                        de uitnodiging tot de vergadering geafficheerd worden op de schietstand. Deze wijzigingen of
                        toevoegingen zullen besproken en gestemd worden tijdens de jaarlijks algemene vergadering.
                        <br />
                        <br />
                        <b>Schorsingen</b>
                        <br />
                        In geval van overtredingen van één of meerdere artikelen van dit reglement of het schietreglement,
                        kunnen leden geschorst worden. De mate van maatregel is afhankelijk van de overtreding(en) en
                        wordt per geval bepaald door de bestuursvergadering. Effectieve schorsingen kunnen enkel opgelegd
                        worden door een gewone ledenvergadering.
                    </p>
                    <br />
                    <Heading text={'Lid worden, zijn en blijven'} light={false} subHeading={true} />
                    <Divider />
                    <p>
                        Lid worden van onze vereniging kan door betaling van het lidgeld. Alvorens een uitnodiging te krijgen
                        voor het betalen van het lidgeld is een goedkeuring van het bestuur noodzakelijk. Het bestuur kan een
                        aanvraag tot aansluiting van een nieuw lid weigeren. Deze weigering dient bekrachtigd te worden met
                        een eenvoudige meerderheid op de eerste bestuursvergadering volgend op de aanvraag. De motivatie
                        is mondeling en enkel de uitslag zal vermeld worden in de notulen van de vergadering. Ieder nieuw lid
                        dat zich aansluit, zal eerst een jaar proeflid zijn. Na het eerste volledig jaar zal de ledenvergadering
                        beslissen of het lid voldoet aan de eisen die onze sport stelt, zoals sportiviteit, veiligheid enz. Dit kan
                        enkel gebeuren op de algemene ledenvergadering en op vraag van een vast lid één maand voor de
                        algemene vergadering. Een lid kan enkel weggestemd worden met 75 % tegenstemmen. Indien een
                        lid na een eerste volledig jaar uitgenodigd wordt om lidgeld te betalen zonder dat er een stemming is
                        geweest, zal deze persoon automatisch het vaste lidmaatschap hebben, zolang er lidgeld betaald
                        wordt. Door aan te sluiten bij onze vereniging verbindt men zich ertoe zich naar de reglementen te
                        gedragen. Personen die de reglementen en de richtlijnen van de club niet naleven, zeker tegen de
                        veiligheid, kunnen geschorst worden. Als lid verbindt men zich ertoe om mee te werken waar nodig
                        is, steeds op vrijwillige basis, onder andere voor het onderhoud van de schietstand, het materiaal en
                        de doelen, dit is een taak voor iedereen.
                    </p>
                    <br />
                    <Heading text={'Ontslag'} light={false} subHeading={true} />
                    <Divider />
                    <p>
                        Bestuursleden die ontslag willen nemen, zullen drie maanden op voorhand het sportbestuur hiervan
                        verwittigen. Leden die ontslag willen nemen, kunnen dit gewoon aan het bestuur melden. Hen zal
                        geen uitnodiging tot betalen van lidgeld meer gestuurd worden. Leden die ontslag willen nemen om
                        bij een andere club aan te sluiten zullen hiervoor de procedures volgen opgelegd door de
                        handboogliga. De club zal nooit lidgeld terugbetalen, met uitzondering van leden die na meerdere
                        schorsingen uitgesloten worden. Hen zal het lidgeld voor de resterende kwartalen terugbetaald
                        worden met aftrek van de ligabijdrage. Schorsingen kunnen opgelegd worden door het bestuur, na
                        herhaaldelijke inbreuken op de reglementen (tegen de veiligheid). Een schorsing kan nooit langer dan
                        een week duren. Na drie schorsingen kan het bestuur overwegen een lid te ontslaan. Hiervoor kan
                        een gewone ledenvergadering worden bijeengeroepen, waar het te schorsen lid zich tegenover de club
                        kan verdedigen.
                    </p>
                    <br />
                    <Heading text={'Openingstijden'} light={false} subHeading={true} />
                    <Divider />
                    <p>
                        De binnenschietstand kan dagelijks gebruikt worden door de leden tijdens de openingsuren van de
                        cafetaria. Jeugdleden onder 18 jaar mogen zich nooit alleen op de schietstand bevinden, er moet altijd
                        een volwassen persoon aanwezig zijn. De schietstand kan gebruikt worden voor initiaties aan groepen,
                        op dat moment kan er niet individueel geschoten worden. Dit zal twee weken op voorhand op het
                        bord bekendgemaakt worden in de club. Bij onderhoudswerken geheel of gedeeltelijk, waarbij er een
                        risico zou zijn, zal er ook niet kunnen geschoten worden. Activiteiten ingericht door het bestuur zullen
                        altijd voorrang krijgen op persoonlijke training.
                        <br />
                        <br />
                        De buitenschietstand is alleen te gebruiken als men veilig kan schieten Wij beschikken momenteel over
                        enkele doelen buiten, die kunnen geplaatst worden op afstanden naar keuze, met dien verstande dat
                        de veiligheid op het terrein moet gewaarborgd blijven. De leden zullen zich hier steeds houden aan
                        de richtlijnen van het bestuur voor die dag.
                    </p>
                    <br />
                    <Heading text={'Materiaal'} light={false} subHeading={true} />
                    <Divider />
                    <p>
                        Elk clublid zal zelf voorzien in eigen schietmateriaal, boog, tab, armbeschermer, pijlen enz.
                        Clubmateriaal, voor zover voorradig, kan gebruikt worden door clubleden; het kan in geen geval op
                        maat gemaakt worden van de gebruiker. Clubmateriaal wordt op dagbasis ter beschikking gesteld
                        door de materiaalverantwoordelijke of een bestuurslid. Het bestuur of de club kan NOOIT
                        verantwoordelijk gesteld worden voor breuk aan persoonlijk, of clubmateriaal. Verhuur van ander
                        materiaal: de club kan al zijn materialen verhuren. Hiervoor zullen prijzen berekend worden die nooit
                        minder dan 1/3de kunnen zijn van wat er in het commerciële circuit gevraagd wordt. Het materiaal
                        mag nooit voor commerciële doeleinden gebruikt worden. Breuk en verlies zal altijd tegen
                        nieuwwaarde aangerekend worden.
                        <br />
                        Indien het clublid eigen materiaal op de club laat staan, is dit geheel op eigen risico en
                        verantwoordelijkheid. In geen geval kan de club, zijn leden, noch zijn bestuur verantwoordelijk worden
                        gehouden voor verlies of beschadiging.
                        <br />
                        <br />
                        <b>Verhuur van bogen</b>
                        <br />
                        Elk jeugdlid kan een boog huren. De boog zal zoveel mogelijk aangepast zijn aan het kunnen van deze
                        persoon. Dit verhuren gebeurt op jaarbasis. De boog zal onderhouden worden door de club. Per jaar
                        zal 1 plastic loper vervangen worden door de club; meerdere plastic lopers zullen door het lid zelf
                        betaald worden. De boog wordt verhuurd met 3 pijlen dewelke ook door de club onderhouden zullen
                        worden. Breuk veroorzaakt door andere redenen dan sleet zullen door het lid vergoedt moeten
                        worden (verlies, stuk enz.). Het enige wat een hurend lid zich moet aanschaffen is een vingerspreider
                        (tab), armbeschermer en pijlenkoker.
                    </p>
                    <br />
                    <Heading text={'Schietstand'} light={false} subHeading={true} />
                    <Divider />
                    <b>Binnen</b>
                    <br />
                    <ul className='md:ml-16 ml-6'>
                        <li className='list-disc'>iedereen is verantwoordelijk voor de veiligheid op de schietstand; aanwijzingen dienen
                            onmiddellijk opgevolgd te worden (daarna is er ruimte voor discussie over de correctheid van
                            de aanwijzingen)
                        </li>
                        <li className='list-disc'>het gebruik van alcoholische drank op de schietstand tijdens het schieten is ten strengste
                            verboden; dronken personen zullen onmiddellijk van de schietstand verwijderd worden; dit is
                            een verantwoordelijkheid van alle leden</li>
                        <li className='list-disc'>mensen vreemd aan de vereniging zullen zich ook aan al onze regels houden op de
                            schietstanden</li>
                        <li className='list-disc'>beginnende schutters moeten zich strikt houden aan de aanwijzingen en richtlijnen van
                            trainer, begeleider of andere meer ervaren schutters</li>
                        <li className='list-disc'>richt nooit een uitgetrokken boog op een persoon, zelfs niet als er geen pijl opligt</li>
                        <li className='list-disc'>leg nooit een pijl op je boog alvorens alle personen zich achter de schietlijn bevinden</li>
                        <li className='list-disc'>trek nooit een boog open op een andere plaats dan de schietlijn</li>
                        <li className='list-disc'>bij het opspannen van de boog zal de pijl altijd naar het doel gericht zijn</li>
                        <li className='list-disc'>men zal nooit meer dan 1 meter schuin schieten</li>
                        <li className='list-disc'>elke schutter heeft recht op 75 cm schietruimte; onze schietstand meet 6 meter breedte, dus
                            er kunnen nooit meer dan 8 schutters gelijktijdig aan de lijn staan</li>
                        <li className='list-disc'>indien er zich meer dan 8 schutters op de schietstand bevinden zal men in meerdere lijnen
                            schieten; men gaat pas pijlen trekken als iedereen geschoten heeft</li>
                        <li className='list-disc'>indien men op meer dan 1 lijn schiet, zal men maximaal 3 pijlen schieten om wachttijden van
                            andere schutters te beperken</li>
                        <li className='list-disc'>bij het trekken van de pijlen houden de schutters dewelke geen pijlen aan het trekken zijn
                            zeker 1 meter afstand van de doelen</li>
                        <li className='list-disc'>het is op de schietstand verboden te lopen</li>
                        <li className='list-disc'>het is toeschouwers niet toegestaan om zonder toestemming met de schutters naar de doelen
                            te lopen; indien ze toestemming hebben houden ze ook 1 meter afstand van de doelen
                        </li>
                        <li className='list-disc'>wachtende schutters en toeschouwers mogen de schutters aan de schietlijn niet storen, dit
                            houdt in, dat men 3 meter afstand moet houden van de schutters en dat men op de
                            schietstand niet te lawaaierig is</li>
                        <li className='list-disc'>het bestuur kan elke avond beslissen of er licht en geluidssignalisatie gebruikt wordt, iedereen
                            heeft zich er dan aan te houden</li>
                        <li className='list-disc'>personen die het schietreglement niet naleven kunnen ten allen tijde gevraagd worden voor
                            die avond te stoppen met schieten; dit houdt geenszins een uitsluiting in, het is louter een
                            veiligheidsmaatrege</li>
                    </ul>
                    <br />
                    <b>Voor de gezelligheid</b>
                    <ul className='md:ml-16 ml-6'>
                        <li className='list-disc'>kom niet aan andermans materiaal
                        </li>
                        <li className='list-disc'>plaats je koffer van de tafel na het monteren van je boog, vermijd dat iemand op de grond zijn
                            boog moet monteren</li>
                        <li className='list-disc'>afval hoort in de afvalbak, is hij vol, leeg hem dan in de container</li>
                        <li className='list-disc'>de schietstand proper houden is ons aller taak, neem ook uw beurt eens</li>
                    </ul>
                    <br />
                    <b>Buiten</b>
                    <ul className='md:ml-16 ml-6'>
                        <li className='list-disc'>nooit omhoog schieten</li>
                        <li className='list-disc'>clout schieten kan enkel onder begeleiding van een ervaren schutter</li>
                        <li className='list-disc'>wie bewust zijn pijl naast of over het doel schiet, en naderhand moet gaan zoeken, beperkt de
                            tijd dat andere schutters kunnen schieten; schutters die dit meerdere malen opzettelijk doen
                            mogen voor die avond afspannen</li>
                        <li className='list-disc'>het domein wordt nog door andere mensen gebruikt, kijk dus dubbel uit</li>
                        <li className='list-disc'>nooit schieten zonder de schietstand beveiligd te hebben met linten</li>
                        <li className='list-disc'>alle rommel dient meegenomen en in de afvalbak gegooid te worden</li>
                        <li className='list-disc'>na het schieten alles opruimen, opbergen en doel afsluiten</li>
                        <li className='list-disc'>alle trainingen worden zoveel mogelijk volgens de geldende FITA reglementen gehouden</li>
                    </ul>
                    <br />
                    <Heading text={'Gelezen en goedgekeurd'} light={false} subHeading={true} />
                    <Divider />
                    <p>Iedereen die wil aansluiten bij onze club zal deze reglementen handtekenen, voorafgegaan door de
                        datum en de tekst 'gelezen en goedgekeurd'. Voor jeugdleden zal één van de ouders tekenen.
                        Jeugdleden die 18 geworden zijn moeten zelf tekenen. Deze regels en reglementen zijn steeds
                        beschikbaar op de club, zodat ieder lid ze ten allen tijde kan raadplegen. Ieder lid zal op simpele vraag
                        een kopij van deze reglementen kunnen bekomen mits betaling van de kopieerkosten. </p>
                    <br />
                    <Heading text={'Bijzonder reglement A: Jeugdreglement'} light={false} subHeading={false} />
                    <Divider />
                    <p>
                        Daar discipline en beleefdheid op en naast de schietlijn een must is zijn wij als club zo vrij een strikte
                        reglementering te handhaven zodat we een blijvende uitstraling blijven bezitten.
                        <br /><br />
                        Een beleefde begroeting van de aanwezigen op de schietstand, voor wedstrijd of training, is gewenst.
                        <br /><br />
                        Respect voor elkaar, elkaars eigendom, materiaal van de club, de lokalen die ter beschikking gesteld
                        worden.
                        <br /><br />
                        Op training verliezen we geen tijd door zelf afgeleid te zijn, anderen af te leiden, we letten op, en
                        voeren het gevraagde uit. Graag toiletbezoek voor of na training! Tijdens de training kan dit storend
                        op ophoudend zijn.
                        <br /><br />
                        Pocketgames of mp3’s horen niet thuis op training of wedstrijd.
                        <br /><br />
                        Grof taalgebruik, ruzies, vechten, pesten of racisme zijn uit den boze!
                        <br /><br />
                        Het ter beschikking gestelde materiaal wordt op elke training of wedstrijddag meegebracht in de staat
                        zoals gevraagd. Beschadigingen of ontbrekende stukken worden onmiddellijk gemeld aan de
                        verantwoordelijke.
                        <br /><br />
                        De uren van de geleide trainingen op woensdag en vrijdag dienen gerespecteerd te worden.
                        <br /><br />
                        We verwaarlozen onze studies niet, het handboogschieten is en blijft een sport, studies zijn uw
                        toekomst!
                        <br /><br />
                        Er is ook geen kliekjesvorming, niet qua leeftijd, speelkwaliteit, huidskleur, haarkleur en dergelijke, we
                        zijn allemaal RHB en kameraden.
                        <br /><br />
                        De kledij word gedragen en verzorgd zoals het hoort, en indien nodig gemerkt om verlies te
                        voorkomen.
                        <br /><br />
                        Trainers, afgevaardigden, bestuursleden en ook de ouders worden gevraagd mede hierop toe te zien,
                        ongeacht om welke schutter het gaat.
                        <br /><br />
                        Boog + Pijl = Wapen! Onvoorzichtigheid, onoplettendheid en ongehoorzaamheid leiden tot
                        ongevallen. Blijf dus steeds attent.
                    </p>
                    <br />
                    <Heading text={'Bijzonder reglement B: Aansprakelijkheid minderjarige schutters'} light={false} subHeading={false} />
                    <Divider />
                    <p>
                        Onze infrastructuur laat niet toe dat elke ouder de trainingen volgt vanop de schietstand. Ouders
                        kunnen steeds wachten in de cafetaria van Capenberg. Anderzijds is het niet voor elke ouder mogelijk
                        om permanent aanwezig te blijven. Uiteraard blijft u als ouder welkom maar indien u niet bij uw kind
                        blijft, en u zo de aansprakelijkheid overdraagt op de club, dan dient u rekening te houden met
                        volgende:
                    </p>
                    <ul className='md:ml-16 ml-6'>
                        <li className='list-disc'>Het verdient de aanbeveling dat minderjarige kinderen door de ouder/voogd worden gebracht
                            tot op de schietstand. Na de training verdient het de aanbeveling dat u de minderjarige komt
                            afhalen aan de schietstand. Dus niet aan de ingang van het sportcomplex, de parking of een
                            andere locatie. </li>
                        <li className='list-disc'>Voor minderjarigen die niet gebracht/afgehaald worden, nemen wij als bestuur aan dat u hun
                            maturiteit hoog genoeg inschat, opdat zij zelf de weg van en naar de club kunnen afleggen. </li>
                        <li className='list-disc'>Het bestuur zorgt voor toezicht op de schietstand. Hetzij op de binnenstand, hetzij op de
                            buitenstand. Indien er gebruik gemaakt wordt van de buitenstand, dan is het de minderjarige
                            toegestaan om binnen het materiaal te nemen en zich dan naar de buitenstand te begeven.
                            Pas wanneer de minderjarige zich aanmeldt op de in gebruik genomen schietstand zal het
                            toezicht van de club aanvatten. </li>
                        <li className='list-disc'>In géén enkel geval kunnen wij toezicht voorzien op de terreinen van het complex
                            Oxaco/Capenberg behoudens op onze in gebruik genomen schietstand(en). </li>
                        <li className='list-disc'>Het is de minderjarige, waarvan de ouder/voogd zich niet op de schietstand bevindt, verboden
                            zich van het toezicht te onttrekken. Een sanitair bezoek moet gevraagd worden en dient
                            uitsluitend voor sanitaire behoeften. De minderjarige dient zich zonder dralen of verpozen
                            van en naar het sanitair te begeven. Indien de ouder/voogd aanwezig is, blijft deze
                            verantwoordelijk voor de minderjarige wanneer deze de schietstand verlaat. </li>
                        <li className='list-disc'>In géén enkel geval is het bestuur verantwoordelijk indien een minderjarige de schietstand
                            betreed buiten de trainingsuren. Na afloop van de training komt het toezicht te vervallen. </li>
                        <li className='list-disc'>De schietstand is géén kantine. Het nuttigen van dranken, spijzen en versnaperingen kan enkel
                            mits voorafgaand akkoord van een verantwoordelijke. </li>
                        <li className='list-disc'>De minderjarige dient zich steeds te houden aan instructies en aanwijzingen van trainer,
                            bestuursleden en verantwoordelijken.</li>
                        <li className='list-disc'>De ouder/voogd dient ons voor aanvang van de training op de hoogte te brengen indien de
                            minderjarige een medische of mentale aandoening heeft die het normale verloop van een
                            training kunnen beïnvloeden. Tevens dient de ouder/voogd ons te verwittigen indien zij weet
                            hebben dat de minderjarige een afwijkend gedrag of houding heeft, die tot uiting zou kunnen
                            komen (terughoudendheid, agressie, spanning, …). Behoudens andersluidende beslissing van
                            de verantwoordelijke die op dat ogenblik aanwezig is, dient de ouder/voogd in voornoemd
                            geval, aanwezig te blijven tijdens de training. </li>
                        <li className='list-disc'>Bij ongeval zal er desgevallend eerste hulp toegediend worden en zullen de gangbare
                            nooddiensten verwittigd worden. </li>
                    </ul>
                    <br />
                    <Heading text={'Bijzonder reglement C: Toelichtingsnota Jeugdtraining'} light={false} subHeading={false} />
                    <Divider />
                    <p>
                        De jeugdtrainingen zijn op vrijdagavond van 19.00h tot 21.00h, gelieve deze uren zo veel mogelijk te
                        respecteren. Als je niet klaar bent om 19.30h, kan de trainer, jeugdbegeleider of verantwoordelijke
                        beslissen dat je niet meer kan deelnemen aan de training!
                        <br />
                        <br />
                        Elke 3e vrijdag van de maand kan een speciale jeugdtraining plaatsvinden waarbij het blazoen wordt
                        geruild voor een andere uitdaging.
                        <br /><br />
                        Een jeugdschutter start met schieten vanop de 12m lijn. Pas na het behalen van het juiste diploma (zie
                        bijzonder reglement D) kan er overgegaan worden naar de 18m lijn.
                        <br /><br />
                        Als een schutter een poging wenst te ondernemen om een hoger diploma te behalen, dient dit voor
                        aanvang vermeld te worden aan de verantwoordelijke of trainer. Een apart doel/blazoen zal hiervoor
                        gebruikt worden en er wordt iemand aangeduid voor het noteren van de punten. Een begonnen
                        poging wordt steeds afgewerkt (dus alle 36 pijlen) en niet afgebroken omdat het gewenste resultaat
                        niet behaald wordt. Sportiviteit (ook tegenover uw eigen)blijft belangrijker dan prestatie.
                        <br /><br />
                        Elke jeugdschutter dient minstens één poging per kwartaal te ondernemen. Indien meerdere pogingen
                        in één kwartaal werden ondernomen telt steeds de hoogste. Op het einde van elk kwartaal zullen de
                        resultaten opgehangen worden op het mededelingsbord zodat elke jeugdschutter zijn/haar evolutie
                        kan opvolgen. Officiële wedstrijden, zowel binnen als buiten de club, tellen hiervoor mee; als op de
                        juiste afstand geschoten wordt.
                        <br /><br />
                        <u><b>Verloop:</b></u>
                        <br />
                    </p>
                    <ul className='md:ml-16 ml-6'>
                        <li className='list-decimal'>Maak u klaar en tuig uw boog op. Ruim koffer, kledij en tassen op zodat ze niet rondslingeren
                            op de schietstand. </li>
                        <li className='list-decimal'>Denk aan uw opwarming! Een goede opwarming maakt dat je gelijkmatiger schiet en het helpt
                            om blessures te voorkomen. Als je moet wachten, herhaal dan je opwarmingsoefeningen.</li>
                        <li className='list-decimal'>Schrijf je naam op het bord als je schietensklaar bent. Hou alle schietlijnen zo goed mogelijk
                            bezet, dus geen 4 lijnen van 3 schutters maar 2 lijnen van 6 schutters. Eens je naam vermeld
                            op het bord, weet je de plaats die je die training zal schieten. Veeg ook je naam van het bord
                            als je stopt met schieten.</li>
                        <li className='list-decimal'>Op de blauwe strook komen en enkel de schutters die aan beurt zijn en zij die deze schutters
                            trainen of in het oog houden. </li>
                        <li className='list-decimal'>Een schietbeurt bestaat uit 3 pijlen</li>
                        <li className='list-decimal'>Hou zelf het verloop van de schietbeurten in de gaten. Ben je afgeleid of opgehouden, ga dan
                            niet laattijdig naar de schietlijn. Als jouw reeks al begonnen is aan de schietbeurt, en je stond
                            niet klaar aan de lijn, dan wacht je tot de volgende beurt</li>
                        <li className='list-decimal'>Op drukke avonden kan een lijnverantwoordelijke het verloop van de schietbeurten regelen
                            door middel van een signaal. </li>
                        <li className='list-decimal'>Na afloop ruim je terug alles netjes op en kijk even rond of de schietstand er nog netjes bijligt.
                            Wij hebben geen personeel, dus ruim rommel even op, ook al is dit niet van jou.</li>
                    </ul>
                    <br />
                    <Heading text={'Bijzonder reglement D: Afstanden – Blazoenen – Diploma’s'} light={false} subHeading={false} />
                    <Divider />
                    <p>Er wordt geschoten op geldige internationale
                        blazoenen.
                        <br /><br />
                        Jeugdschutters onder de 15 jaar schieten steeds
                        van op de 12m lijn, tenzij het diploma ‘plastiek’
                        behaald werd, dan mogen zij naar de 18m.
                        <br /><br />
                        Indien er op drukke avonden zowel 18m
                        blazoenen (diameter 40cm) als 25m blazoenen
                        (diameter 60cm) op de doelen hangen, dan
                        verdient het de voorkeur dat 18m schutters die
                        het diploma ‘carbon’ al behaald hebben, de 25m
                        blazoenen laten voor de 18m schutters die dit
                        diploma nog niet hebben.
                        <br /><br />
                        Elk ingeschreven lid, jeugd en volwassenen, kan een diploma behalen. De regels voor de diploma’s zijn
                        voor iedereen dezelfde:
                        <br /><br />
                        <ul className='md:ml-16 ml-6'>
                            <li className='list-decimal'>Een diplomaschieting bestaat steeds uit 36 schoten in 12 reeksen van 3 pijlen. </li>
                            <li className='list-decimal'>Op een officiële wedstrijd binnen of buiten de club - of -</li>
                            <li className='list-decimal'>Op een training, waarbij je steeds op voorhand afspreekt dat je een diplomaschieting wenst te
                                houden.</li>
                            <li className='list-decimal'>De puntentelling gebeurt volgend de officiële regels, en wordt opgetekend door een
                                verantwoordelijke of bestuurslid. Indien er meerder schutters zijn die aan een
                                diplomaschieting wensen deel te nemen, kan vòòr aanvang van de schieting de
                                verantwoordelijke toestemming verlenen dat de punten worden opgeschreven door de
                                schutters in onderling overleg. De verantwoordelijke blijft wel aanwezig om ingeval van twijfel
                                of discussie een oordeel te vellen. </li>
                        </ul>
                    </p>
                    <br />
                    <Heading text={'Soorten diploma\'s'} light={false} subHeading={true} />
                    <Divider />
                    <DataTable
                        heading={['Diploma', 'Afstand', 'Blazoen', 'Punten op 360']}
                        rows={[
                            ['Papier', '12m', '25m (60cm Ø)', '180'],
                            ['Karton', '12m', '25m (60cm Ø)', '240'],
                            ['Plastiek', '12m', '25m (60cm Ø)', '300'],
                        ]}
                    />
                    <DataTable
                        heading={['Diploma', 'Afstand', 'Blazoen', 'Punten op 360']}
                        rows={[
                            ['Blik', '18m', '18m (60cm Ø)', '180'],
                            ['Alluminium', '18m', '18m (60cm Ø)', '240'],
                            ['Carbon', '18m', '18m (60cm Ø)', '300'],
                        ]}
                    />
                    <DataTable
                        heading={['Diploma', 'Afstand', 'Blazoen', 'Punten op 360']}
                        rows={[
                            ['Brons', '18m', '18m (40cm Ø)', '180'],
                            ['Zilver', '18m', '18m (40cm Ø)', '240'],
                            ['Goud', '18m', '18m (40cm Ø)', '300'],
                        ]}
                    />
                    <DataTable
                        heading={['Diploma', 'Afstand', 'Blazoen', 'Punten op 360']}
                        rows={[
                            ['Platina', '18m', 'Trispot', '300'],
                        ]}
                    />
                    <DataTable
                        heading={['Diploma', 'Afstand', 'Blazoen', 'Punten op 360']}
                        rows={[
                            ['Brons', '25m', '25m (60cm Ø)', '180'],
                            ['Zilver', '25m', '25m (60cm Ø)', '240'],
                            ['Goud', '25m', '25m (60cm Ø)', '300'],
                        ]}
                    />
                    <DataTable
                        heading={['Diploma', 'Afstand', 'Blazoen', 'Punten op 360']}
                        rows={[
                            ['Brons', '50m', '50m (80cm Ø)', '180'],
                            ['Zilver', '50m', '50m (80cm Ø)', '240'],
                            ['Goud', '50m', '50m (80cm Ø)', '300'],
                        ]}
                    />
                    <DataTable
                        heading={['Diploma', 'Afstand', 'Blazoen', 'Punten op 360']}
                        rows={[
                            ['Brons', '70m', '70m (122cm Ø)', '180'],
                            ['Zilver', '70m', '70m (122cm Ø)', '240'],
                            ['Goud', '70m', '70m (122cm Ø)', '300'],
                        ]}
                    />
                    <br />
                    <br />
                    <Heading text={'Bijzonder reglement E: de Koningsschieting'} light={false} subHeading={false} />
                    <Divider />
                    <ul className='md:ml-16 ml-6'>
                        <li className='list-disc'>De Koningsschieting kent 4 categoriën: Koning Recurve, Koning Compound, Koning Hout op Hout, Koning Jeugd (onder 15 jaar) </li>
                        <li className='list-disc'>Iedere persoon die bij de club is aangesloten mag deelnemen, mits het betalen van de op
                            voorhand medegedeelde inleg. </li>
                        <li className='list-disc'>Men kan maar schieten in één categorie.</li>
                        <li className='list-disc'>Er wordt geschoten op een stop in ring van 9mm, van op een afstand van 18m</li>
                        <li className='list-disc'>De wedstrijd vangt aan door de aftredende koning met een voorschot, hierna wordt in
                            alfabetische volgorde vanaf de koning geschoten.</li>
                        <li className='list-disc'>Er zijn 10 reeksen van 1 pijl. De reeksen beginnen telkens bij de koningen dienen volledig
                            afgewerkt te worden. </li>
                        <li className='list-disc'>Als iemand de stop schiet is hij/zij koning, op voorwaarde dat niemand in dezelfde reeks de
                            stop nog schiet. </li>
                        <li className='list-disc'>Indien meerdere personen in een reeks en binnen dezelfde categorie, de stop nog schiet,
                            wordt er tussen deze personen verder afgekampt. Als één van de personen een tweede maal
                            de stop schiet, of na de 10 reeksen de hoogste score heeft, is koning. </li>
                        <li className='list-disc'>Als niemand na 10 reeksen de stop heeft geschoten, wordt de persoon met het meest aantal
                            punten de koning. Indien er meerdere personen zijn met een gelijk aantal punten, dan telt het
                            totaal der tienen. Als het aantal tienen gelijk is, dan wordt er tussen die personen een shootoff gehouden met één pijl</li>
                        <li className='list-disc'>De deelnemers die in een reeks na het koningsschot de stop schieten, of na 10 reeksen de
                            hoogste score hebben, worden eerste en tweede prins.</li>
                        <li className='list-disc'>Een jeugdschutter die de leeftijd van 15 jaar niet bereikt heeft op de dag van de
                            koningsschieting, mag kiezen tussen 12m of 18m, indien het diploma van 18m behaald werd. </li>
                        <li className='list-disc'>Een jeugdschutter van 15j of ouder schieten verplicht op 18m. </li>
                        <li className='list-disc'>De jeugdschutter die vanop 12m het eerst de stop schiet, of het hoogst aantal punten (bij de jeugd op 12m) heeft, is de Jeugdkoning.</li>
                    </ul>
                    <br />
                    <Heading text={'Kledij'} light={false} subHeading={false} />
                    <Divider />
                    <p>
                        Onze kleuren voor de kledij:<br />
                        Bovenkledij: groen met witte opdruk. Indien gewenst kan een T-shirt, polo, trainingsvest en/of
                        regenjas besteld worden.
                        <br /><br />
                        <b>Op officiële wedstrijden vragen wij u met aandrang om onze T-shirt/polo te dragen en de
                            sportbroek.</b>
                        <br /><br />
                        Een zwarte trainingsbroek en aangepast schoeisel (geen open schoeisel zoals sandalen!) maakt het
                        geheel compleet. Indoor zijn sportschoenen altijd verplicht.
                        <br /><br />
                        Op training telt deze verplichting niet en vragen wij een ordentelijke kleding. Uiteraard strekt het tot
                        onze en uw fierheid indien de kleuren ook gedragen worden op training. Op die wijze komt onze club
                        positief in het vizier.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default ClubReglement;