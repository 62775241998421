import Title from '../../common/Title';
import BouncingArrow from '../../common/BouncingArrow';
import Button from '../../common/Button';
import Divider from '../../common/Divider';
import SubTitle from '../../common/SubTitle';

const Header = () => {

    return (
        <header className='flex flex-col items-center max-w-full'>
            <Title text={'Robin Hood Boechout'} />
            <Divider />
            <SubTitle text={'Sportvereniging van de handboogschutter'} />
            <div className='w-72 m-12'>
                <Button text={'Word lid van onze club!'} link={'/lidgelden'} />
            </div>
            <BouncingArrow location={'.featured'} size={36} />
        </header>
    );
};

export default Header;