import Title from '../common/Title';
import Navbar from './Navbar';


interface BannerProps {
    title: string,
    image: string
}

const Banner: React.FC<BannerProps> = ({ title, image }) => {
    return (
        <section className={`md:h-[45vh] h-[40vh] bg-cover bg-center`} style={{ backgroundImage: `url(${image})` }}>
            <div className='bg-[rgba(0,0,0,0.5)] md:h-[45vh] h-[40vh]'>
                <Navbar />
                <div className='flex justify-center md:mt-24 mt-12'>
                    <Title text={title} />
                </div>
            </div>
        </section>
    );
};

export default Banner;