import Banner from '../components/layout/Banner';
import Footer from '../components/layout/Footer';
import ProtocolSeksueleIntimidatie from '../components/layout/footer/ProtocolSeksueleIntimidatie';

import BackgroundImage from '../assets/images/trainingen.jpg';


export default function SeksueleIntimidatie() {
    return (
        <>
            <Banner title={'Seksuele Intimidatie'} image={BackgroundImage} />
            <ProtocolSeksueleIntimidatie />
            <Footer />
        </>
    );
}