import React from 'react';
import { FaChevronDown } from 'react-icons/fa';

interface BouncingArrowProps {
    location: string;
    size: number;
}

const BouncingArrow: React.FC<BouncingArrowProps> = ({ location, size }) => {
    const scrollToSection = () => document.querySelector(location)?.scrollIntoView({ behavior: 'smooth' });
    return (
        <div className='absolute text-center text-primary-text bottom-16 animate-bounce'>
            <a onClick={scrollToSection} className='cursor-pointer'>
                <FaChevronDown size={size} />
            </a>
        </div>
    );
};

export default BouncingArrow;