import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import nlLocale from '@fullcalendar/core/locales/nl';

interface DateTimeObject {
    date: string;
    time: string;
}

interface GoogleCalendarEvents {
    date: string;
    start: DateTimeObject;
    end: DateTimeObject;
    details: {
        title: string;
        description?: string;
    };
    link: string;
}

interface CalendarEvents {
    title: string;
    start?: string;
    end?: string;
    backgroundColor?: string;
    borderColor?: string;
    color?: string;
    url?: string;
}

enum CalendarItemColor {
    'robin-hood-boechout' = 'var(--accent)',
    'luchtdoelschieten' = 'purple',
    'boogsport-vlaanderen' = '#EDC812',
    'antwerpse-federatie' = '#D50000'
}

const Calendar = () => {
    const [events, setEvents] = useState<CalendarEvents[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [initialView, setInitialView] = useState('dayGridMonth');
    const [calendarToolbar, setCalendarToolbar] = useState({
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,dayGridWeek,dayGridDay'
    });
    const calendarRef = useRef<FullCalendar>(null!);

    function formatDateTime(dateTime: DateTimeObject) {
        let [day, month, year] = dateTime.date.split('/');
        let dateObj = new Date(Number(year), Number(month) - 1, Number(day) + 1);

        let formattedDate = dateObj.toISOString().split('T')[0];
        return formattedDate + 'T' + dateTime.time.replace('u', ':') + ':00';
    }

    function createEventItems(data: GoogleCalendarEvents[], calendar: string) {
        let eventList: CalendarEvents[] = [];
        data.forEach((event: GoogleCalendarEvents) => {

            eventList.push({
                title: event.details.title,
                start: formatDateTime(event.start),
                end: formatDateTime(event.end),
                backgroundColor: CalendarItemColor[calendar as keyof typeof CalendarItemColor],
                borderColor: CalendarItemColor[calendar as keyof typeof CalendarItemColor],
                url: event.link
            });
        });

        return eventList;
    }

    const updateView = () => {
        const width = window.innerWidth;
        let newView;
        let toolbar;
        if (width >= 1024) {
            // Desktop view
            newView = 'dayGridMonth';
            toolbar = { left: 'prev,next today', center: 'title', right: 'dayGridMonth,dayGridWeek,dayGridDay' }
        } else if (width >= 768) {
            // Tablet view
            newView = 'timeGridWeek';
            toolbar = { left: 'prev,next today', center: 'title', right: 'dayGridMonth,dayGridWeek,dayGridDay' }
        } else {
            // Mobile view
            newView = 'listWeek';
            toolbar = { left: 'prev', center: 'today', right: 'next' }
        }

        setInitialView(newView);
        setCalendarToolbar(toolbar);
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            calendarApi.changeView(newView);
        }
    };


    async function getCalendarData(calendarSlug: string) {
        try {
            const response = await axios.get(`https://api.robinhoodboechout.be/api/v1/events/${calendarSlug}`);
            let events = createEventItems(response.data, calendarSlug);
            return events;
        } catch (error) {
            console.log(error);
            return [];
        }
    }

    useEffect(() => {
        const fetchEvents = async () => {
            let rhbEvents = await getCalendarData('robin-hood-boechout');
            let ldsEvents = await getCalendarData('luchtdoelschieten');
            let bsvEvents = await getCalendarData('boogsport-vlaanderen');
            let afEvents = await getCalendarData('antwerpse-federatie');

            let events = rhbEvents.concat(ldsEvents, bsvEvents, afEvents);
            setEvents(events);
        };

        fetchEvents();
        updateView();

        window.addEventListener('resize', updateView);

        return () => window.removeEventListener('resize', updateView);
    }, []);

    if (error) {
        return <div className='py-16 flex flex-col items-center'>Error: {error}</div>;
    }
    return (
        <>
            <div className='h-screen w-full flex flex-col justify-center items-center'>
                <div className='w-full max-w-7xl h-3/4'>
                    <FullCalendar
                        ref={calendarRef}
                        headerToolbar={calendarToolbar}
                        plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
                        initialView={initialView}
                        events={events}
                        locale={nlLocale}
                        height='100%'
                        editable={false}
                    />
                    <CalendarLegend />
                </div>
            </div>

        </>
    );
}

// Calendar Legend Component
const CalendarLegend = () => {
    return (
        <div className='flex flex-wrap mb-4 p-2'>
            <LegendItem color={CalendarItemColor['boogsport-vlaanderen']} label='Boogsport Vlaanderen' />
            <LegendItem color={CalendarItemColor['antwerpse-federatie']} label='Antwerpse Federatie' />
            <LegendItem color={CalendarItemColor['robin-hood-boechout']} label='Robin Hood Boechout' />
            <LegendItem color={CalendarItemColor['luchtdoelschieten']} label='Luchtdoelschieten' />
        </div>
    );
};

// Individual Legend Item
const LegendItem = ({ color, label }: any) => {
    return (
        <div className='flex items-center mr-4'>
            <span
                className='inline-block w-4 h-4 rounded-full mr-2'
                style={{ backgroundColor: color }}
            ></span>
            <span className='text-sm italic'>{label}</span>
        </div>
    );
};

export default Calendar;