import { useEffect, useState } from 'react';
import Divider from '../../../components/common/Divider';
import SectionTitle from '../../../components/common/SectionTitle';
import { FaPen, FaTrash, FaPlus, FaRegStar, FaStar } from 'react-icons/fa';
import axios from 'axios';

interface PostDetails {
    title: string;
    description: string;
    content: string;
    image_location: string;
}

interface NewsPost {
    id: string;
    slug: string;
    post_details: PostDetails;
    tag: string;
    created_at: any;
    published_at: string | null;
    author: string;
    featured: boolean
}

export default function Nieuwsberichten() {
    const [posts, setPosts] = useState<NewsPost[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get(`https://api.robinhoodboechout.be/api/v1/posts`);
                setPosts(response.data);
            } catch (error) {
                setError('Failed to fetch news posts');
                console.error(error);
            }
        };

        fetchPosts();
    }, []);

    const handleDelete = (postId: string) => {
        console.log(postId)
    }

    if (error) {
        return <div className='py-16 flex flex-col items-center'>Error: {error}</div>;
    }

    return (
        <>
            <SectionTitle text={'Nieuwsberichten'} light={false} />
            <Divider />
            <div className='flex flex-col text-slate-700 bg-white shadow-md rounded-xl bg-clip-border'>
                <div className='mx-4 mt-4 text-slate-700 bg-white rounded-none bg-clip-border'>
                    <div className='flex flex-col sm:flex-row items-center justify-between'>
                        <div className='flex flex-col'>
                            <h3 className='text-lg font-semibold text-slate-800'>Lijst van Nieuwsberichten</h3>
                            <p className='text-slate-500'>Update, verwijder of maak een nieuw bericht.</p>
                        </div>
                        <div className='flex gap-2 mt-4 sm:mt-0 sm:flex-col shrink-0'>
                            <a href='/admin/nieuwsberichten/nieuw' className='flex cursor-pointer select-none items-center gap-2 rounded bg-primary py-2.5 px-4 text-xs font-semibold text-white shadow-md shadow-slate-900/10 transition-all hover:shadow-lg hover:shadow-slate-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'>
                                <FaPlus size={15} /> Nieuw Bericht
                            </a>
                        </div>
                    </div>
                </div>
                <div className='p-0 overflow-x-auto'>
                    <table className='w-full mt-4 table-auto min-w-max text-center'>
                        <thead>
                            <tr>
                                <th className='p-4 transition-colors border-y border-slate-200 bg-slate-50'>
                                    <p className='flex items-center justify-center gap-2 font-sans text-sm font-normal leading-none text-slate-500'>Titel</p>
                                </th>
                                <th className='p-4 transition-colors border-y border-slate-200 bg-slate-50'>
                                    <p className='flex items-center justify-center gap-2 font-sans text-sm font-normal leading-none text-slate-500'>Omschrijving</p>
                                </th>
                                <th className='p-4 transition-colors border-y border-slate-200 bg-slate-50'>
                                    <p className='flex items-center justify-center gap-2 font-sans text-sm font-normal leading-none text-slate-500'>Created</p>
                                </th>
                                <th className='p-4 transition-colors border-y border-slate-200 bg-slate-50'>
                                    <p className='flex items-center justify-center gap-2 font-sans text-sm font-normal leading-none text-slate-500'>Status</p>
                                </th>
                                <th className='p-4 transition-colors border-y border-slate-200 bg-slate-50'>
                                    <p className='flex items-center justify-center gap-2 font-sans text-sm font-normal leading-none text-slate-500'>Featured</p>
                                </th>
                                <th className='p-4 transition-colors border-y border-slate-200 bg-slate-50'>
                                    <p className='flex items-center justify-center gap-2 font-sans text-sm font-normal leading-none text-slate-500'>Acties</p>
                                </th>
                            </tr>

                        </thead>
                        <tbody>
                            {posts.map(post => (
                                <tr>
                                    <td className='p-4 border-b border-slate-200'>
                                        <div className='flex flex-col'>
                                            <p className='text-sm text-slate-700 font-semibold'>
                                                {post.post_details.title}
                                            </p>
                                        </div>
                                    </td>
                                    <td className='p-4 border-b border-slate-200'>
                                        <div className='flex flex-col'>
                                            <p className='text-sm font-semibold text-slate-700 text-ellipsis'>
                                                {post.post_details.description}
                                            </p>
                                        </div>
                                    </td>
                                    <td className='p-4 border-b border-slate-200'>
                                        <div className='flex flex-col'>
                                            <p className='text-sm font-semibold text-slate-700'>
                                                {post.created_at}
                                            </p>
                                        </div>
                                    </td>
                                    <td className='p-4 border-b border-slate-200'>
                                        <div className='flex flex-col items-center justify-center w-max mx-auto'>
                                            {post.published_at != null ? (
                                                <div className='relative flex items-center px-2 py-1 font-sans text-xs font-bold uppercase rounded-md select-none whitespace-nowrap text-green-900 bg-green-500/20'>
                                                    <span>Published</span>
                                                </div>
                                            ) : (
                                                <div className='relative flex items-center px-2 py-1 font-sans text-xs font-bold uppercase rounded-md select-none whitespace-nowrap text-slate-500 bg-slate-100'>
                                                    <span>Draft</span>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td className='p-4 border-b border-slate-200'>
                                        <p className='text-sm text-yellow-500 flex justify-center items-center'>
                                            {post.featured ? (
                                                <FaStar size={15} />
                                            ) : (
                                                <FaRegStar size={15} />
                                            )}
                                        </p>
                                    </td>
                                    <td className='p-4 border-b border-slate-200'>
                                        <button key={post.id} className='relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-sans text-xs font-medium uppercase text-slate-900 transition-all hover:bg-slate-900/10 active:bg-slate-900/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'>
                                            <span className='absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2'>
                                                <FaPen size={15} />
                                            </span>
                                        </button>
                                        <button onClick={() => handleDelete(post.id)} className='relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-sans text-xs font-medium uppercase text-slate-900 transition-all hover:bg-slate-900/10 active:bg-slate-900/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'>
                                            <span className='absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2'>
                                                <FaTrash size={15} />
                                            </span>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}