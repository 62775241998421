import React from 'react';

interface SectionTitleProps {
    text: string;
    light: boolean;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ text, light }) => {
    return (
        <h1 className={`${light ? 'text-primary-text' : 'text-black'} font-bold md:text-4xl text-3xl mx-auto`}>{text}</h1>
    );
};

export default SectionTitle;